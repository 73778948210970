<a href="#" (click)="open(content)" class="mb-2 mr-2">Gerenciar</a>

<ng-template #content let-modal>
  <div *ngIf="modalAberto" class="modal-body">
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="border: none">
          <div class="card-header" style="border: none">
            <label for="up" class="update" (click)="update()">
              <fa-icon [icon]="['fas', 'sync-alt']"></fa-icon>
            </label>
            &nbsp;&nbsp;
            <label for="up">
              UP:
              <strong>
                {{ unidadeProdutiva.nomeUnidadeProdutiva }}
              </strong>
            </label>
            &nbsp; &nbsp;
            <label for="up" for="up">
              Produtor/Grupo:
              <strong>
                {{ unidadeProdutiva.produtorGrupo }}
              </strong>
            </label>
            &nbsp; &nbsp;
            <label for="up" for="up">
              Safra de Certificação:
              <strong>
                {{ unidadeProdutiva.safraCertificacao }}
              </strong>
            </label>
            &nbsp; &nbsp;
            <label for="up" for="up">
              Processo:
              <strong>
                {{ unidadeProdutiva.numeroProcesso }}
              </strong>
            </label>
            &nbsp;
            <modal-dados-up
              [unidadeProdutiva]="unidadeProdutiva"
              [safra]="safra"
            ></modal-dados-up>
            &nbsp;
            <modal-historico-abr
              [unidadeProdutiva]="unidadeProdutiva"
            ></modal-historico-abr>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div
      *ngIf="
        unidadeProdutiva.nomeAntigo != null ||
        unidadeProdutiva.nomeAntigoGrupoProdutor != null
      "
    >
      <div
        *ngIf="
          unidadeProdutiva.nomeAntigo != null &&
          unidadeProdutiva.nomeAntigo != undefined
        "
        class="alert alert-info"
        role="alert"
      >
        <fa-icon [icon]="iconInfo" size="sm"></fa-icon>&nbsp; O nome da unidade
        produtiva foi alterado de {{ unidadeProdutiva.nomeAntigo }} para
        {{ unidadeProdutiva.nomeUnidadeProdutiva }}, em
        {{ unidadeProdutiva.dataVigenciaNome | date: "dd/MM/yyyy" }}.
      </div>
      <div
        *ngIf="
          unidadeProdutiva.nomeAntigoGrupoProdutor != null &&
          unidadeProdutiva.nomeAntigoGrupoProdutor != undefined
        "
        class="alert alert-info"
        role="alert"
      >
        <fa-icon [icon]="iconInfo" size="sm"></fa-icon>&nbsp; O vínculo do
        produtor/grupo de produtores foi alterado de
        {{ unidadeProdutiva.nomeAntigoGrupoProdutor }} para
        {{ unidadeProdutiva.produtorGrupo }}, em
        {{ unidadeProdutiva.dataVigenciaGrupoProdutor | date: "dd/MM/yyyy" }}.
      </div>
    </div>
    <div *ngIf="unidadeProdutiva.habilitada === '1'; else elseBlock">
      <ng-container *ngIf="loading; else content">
        <div class="d-flex justify-content-center align-items-center">
          <div class="spinner-border text-primary" role="status"></div>
          <p></p>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;Aguarde...</span>
        </div>
      </ng-container>
      <ng-template #content>
        <multi-steps
          [esconderAndamentoCertificacao]="esconderAndamentoCertificacao"
          [activeStepLabel]="activeStepLabel"
          (clickStep)="onClickStep($event)"
          (showAndamentoCertificacao)="onShowAndamentoCertificacao()"
          [steps]="steps"
        >
        </multi-steps>
        <ng-content *ngIf="!showAndamentoCertificacao"></ng-content>
        <modal-andamento-certificacao
          *ngIf="showAndamentoCertificacao"
          [unidadeProdutiva]="unidadeProdutiva"
        >
        </modal-andamento-certificacao>
      </ng-template>
    </div>
    <ng-template #elseBlock>
      <div style="text-align: center">
        Habilite esta Unidade Produtiva no SINDA para prosseguir com a
        certificação
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-light"
      (click)="modal.dismiss('Save click')"
    >
      Fechar
    </button>
  </div>
</ng-template>
