import { Status } from "src/app/entities/status";

import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-status-convite",
  templateUrl: "./status-convite.component.html",
  styleUrls: ["./status-convite.component.css"],
})
export class StatusConviteComponent implements OnChanges, OnInit {
  @Input() title: string;
  @Input() status: Status | Status[] = [];
  @Input() loading: boolean;

  @Input() shimmerAmount = 1;

  shimmers = [];

  constructor() {}

  ngOnInit(): void {
    this.shimmers = Array(this.shimmerAmount);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status) {
      if (!Array.isArray(this.status)) {
        this.status = [this.status];
      }
    }
  }
}
