import { VcpService } from "src/app/services/vcp.service";
import { Component, Input, OnInit, DoCheck, Output, EventEmitter } from "@angular/core";
import { Status } from "../../entities/status";
import { UnidadeProdutiva } from "../../entities/unidade-produtiva";
import { Step } from "../../shared/multi-steps/multi-steps.component";
import { UpCertificacaoService } from "src/app/services/up-certificacao.service";
import { ModalService } from "src/app/services/modal.service";
import { SubscriptionService } from "src/app/services/subscription.service";
import { finalize } from "rxjs/operators";
import { PermissionService } from "src/app/services/permission-service";
import { PerfilUsuario } from "src/app/entities/perfil";

@Component({
  selector: "modal-certificadora",
  templateUrl: "./modal-certificadora.component.html",
  styleUrls: ["./modal-certificadora.component.css"],
})
export class ModalCertificadoraComponent implements OnInit, DoCheck {
  @Input() unidadeProdutiva: UnidadeProdutiva;

  @Input() esconderAndamentoCertificacao: boolean = false;

  @Output() etapaTrocada = new EventEmitter();


  status: Status;
  statusCertificacao: Status;

  loading = false;
  loadingVCP = true;
  loadingCertificacao = true;

  steps: Step[];
  defaultActiveStepLabel: string;
  activeStepLabel: string;

  modalAberto = false;

  etapaCertificadoAbr = false;

  showAndamentoCertificacao = false;

  constructor(
    private permissionService: PermissionService,
    private vcpService: VcpService,
    private upCertificacaoService: UpCertificacaoService,
    private modalService: ModalService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    this.loading = this.loadingVCP && this.loadingCertificacao;
  }

  _inicializar() {
    this._carregarDefaultActiveStep();
    this._carregarStatusVCP();
    this._carregaStatusCertificacao();
  }

  private _desmontar() {
    this.subscriptionService.unsubscribeAll();
  }

  private _carregarDefaultActiveStep() {
    const defaultActiveStep$ = this.modalService.getDefaultActiveStepLabel()
      .subscribe(novoValor => this.activeStepLabel = novoValor);

    this.subscriptionService.add(defaultActiveStep$);
  }

  private _carregarStatusVCP() {
    this.loadingVCP = true;
    const vcpService$ = this.vcpService
      .getStatusSafraSelecionada(this.unidadeProdutiva)
      .pipe(
        finalize(() => this.loadingVCP = false)
      )
      .subscribe((status) => {
        this.status = status;
      });
    this.subscriptionService.add(vcpService$);
  }

  private _carregaStatusCertificacao() {
    this.loadingCertificacao = true;
    const upCertificacao$ = this.upCertificacaoService.getStatusCertificacao(this.unidadeProdutiva)
      .pipe(
        finalize(() => this.loadingCertificacao = false)
      )
      .subscribe(status => {
        this.statusCertificacao = status;
        this._defineSteps();
      });
    this.subscriptionService.add(upCertificacao$);
  }

  private _defineSteps() {
    if (this.statusCertificacao.etapa.includes("Certificada ABR") || this.statusCertificacao.etapa.toLowerCase().includes("certificação") || this.statusCertificacao.etapa.includes("Certificado")) {
      this.etapaCertificadoAbr = true;
    }
    let perfil=this.permissionService.getPerfisValidos();
    this.steps = [
      {
        label: "VCP",
        active: true,
      }
    ];
    if(!perfil.some(x=>x==PerfilUsuario.CERTIFICADORA_AUDITOR)){
      this.steps.push({
        label: "Certificado ABR",
        active: this.etapaCertificadoAbr,
      });
    }
  
    const etapaDefault = this.etapaCertificadoAbr
      ? "Certificado ABR"
      : "VCP";

    this.modalService.changeDefaultActiveStepLabel(etapaDefault);
  }

  onChangeStep(step: Step) {
    this.activeStepLabel = step.label;
  }

  onShowAndamentoCertificacao() {
    this.showAndamentoCertificacao = !this.showAndamentoCertificacao;
  }

  toggleModalStatus(modalStatus: boolean) {
    this.modalAberto = modalStatus;

    if (!this.modalAberto) {
      return this._desmontar();
    }

    this._inicializar();
  }

  onConcluirVCP() {
    this._inicializar();
    this.etapaTrocada.emit();
  }
}
