<div class="row">
  <div class="col-lg-4">
    <app-labeled-button label="Formulário final BCI">
      <modal-emitir-numero-licenca-bci *ngIf="podeAcessarLicencaBCI || editarLicencaRetorno" (emitido)="onEmitirLicencaBci()" [unidadeProdutiva]="unidadeProdutiva" button>
      </modal-emitir-numero-licenca-bci>
      <div observation>*Após o preenchimento completo a Unidade Produtiva será automaticamente considerada como Licenciada no BCI.</div>
    </app-labeled-button>
    <app-labeled-button label="Upload da Licença BCI (PDF)" *ngIf="(temNumeroLicencaBCI && podeAcessarLicencaBCI) || editarLicencaRetorno">
      <modal-upload-licenca-bci [unidadeProdutiva]="unidadeProdutiva" button (emitido)="onEmitirLicencaBci()"></modal-upload-licenca-bci>
      <div observation>*Só será aceito o arquivo no formato <strong>PDF</strong></div>
    </app-labeled-button>
    <a *ngIf="(temArquivoLicencaBCI && temNumeroLicencaBCI) || editarLicencaRetorno" href="#" (click)="downloadLicencaBCI()" class="mb-2 mr-2">Download da Licença BCI
      (PDF)
    </a>
  </div>
  <div class="col-lg-4">
    <app-status-convite title="Status" [loading]="loadingStatusVdp" [shimmerAmount]="1" [status]="status">
    </app-status-convite>
  </div>
  <div class="col-lg-4 p-lg-0 mt-2 mt-lg-0 row">
    <div class="col-6 pr-lg-0">
      <app-title-number title="Licença BCI" [number]="numeroCertificadoBci"></app-title-number>
    </div>
  </div>
</div>
<div class="d-flex justify-content-end">
  <button *ngIf="showEditarLicenca && !editandoLicencaBCI" (click)="confirmarRetornoBci()" class="btn btn-primary btn-sm">Editar Licença</button>&nbsp;
  
  <div>
    <button (click)="cancelaBCI()" [disabled]="verificaPermissaoBotaoCancelar" class="btn btn-sm btn-dark m-1; d-flex justify-items-center">
      <span>
        <fa-icon [icon]="icon"></fa-icon> Cancelar Licença BCI
      </span>
    </button>
    <div>
      <button  (click)="reprovaBCI()" *ngIf="temArquivoLicencaBCI && !editarLicencaRetorno" [disabled]="verificaPermissaoBotao || clicked" class="btn btn-sm btn-danger m-1">
        <span>
          <fa-icon></fa-icon> Reprovar Unidade Produtiva para o Licenciamento BCI
        </span>
      </button>
      <span [ngbTooltip]="tooltipConfirmar" placement="top">
        <button class="btn btn-sm btn-success m-1" *ngIf="temArquivoLicencaBCI && !editarLicencaRetorno" [disabled]="verificaPermissaoBotao || clicked"
          (click)="aprovaBCI()">Aprovar Unidade Produtiva para o Licenciamento BCI </button>
      </span>
    </div>

  </div>
</div>
