import { VdpService } from "./../../services/vdp.service";
import { finalize } from "rxjs/operators";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";
import { AlertModalService } from "src/app/services/alert-modal.service";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ModalSelecionarCertificadoraComponent } from "../modal-certificadora/modal-selecionar-certificadora/modal-selecionar-certificadora.component";

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EtapaABR } from "../../entities/etapas";
import { Status, StatusPcnc } from "../../entities/status";
import { AppService } from "../../services/app-service";
import { PcncService } from "../../services/pcnc.service";
import { SpinnerService } from "../../services/spinner.service";
import { SubscriptionService } from "../../services/subscription.service";
import { ToastService } from "../../services/toast.service";
import { ToastType } from "src/app/entities/toast";
import * as Sentry from "@sentry/angular";
import { Services } from "src/app/services/service";
import { EtapasEnum } from "../modal-retorno/etapas-enum";

@Component({
  selector: "modal-etapa-pcnc",
  templateUrl: "./modal-etapa-pcnc.component.html",
  styleUrls: ["./modal-etapa-pcnc.component.css"],
})
export class ModalEtapaPCNCComponent implements OnInit, OnDestroy {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Output() concluirEtapa = new EventEmitter();

  status: Status;
  statusVdp: Status;
  statusPcnc: StatusPcnc;
  etapaAtual: any;
  safraVigente = false;
  podeAcessarFormularioPcnc = false;
  podeAcessarPcnc = false;
  podeConcluirPcnc = false;
  podePularEtapaPCNC = false;
  podeSolicitarRetorno: boolean = false;

  podeReenviarEmail = false;

  loadingStatus = true;
  iconInfo = faInfoCircle;

  mapaStatusSolicitacaoRetorno: EtapaABR[] = [
    EtapaABR.PCNC_CONCLUIDO,
    EtapaABR.PCNC_RECUSOU_VCP,
  ];

  constructor(
    private alertModalService: AlertModalService,
    private pcncService: PcncService,
    private vdpService: VdpService,
    private appService: AppService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private subscriptionService: SubscriptionService,
    private modalService: NgbModal,
    private services: Services,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.loading();
  }

  loading() {
    this._carregarStatusPcnc();
    this._carregarStatusVDP();
    this._podeSolicitarRetorno();
  }

  _carregarStatusVDP() {
    this.vdpService
      .getStatusSafraSelecionada(this.unidadeProdutiva)
      .subscribe((res) => {
        this.statusVdp = res;
      });
  }
  ngOnDestroy() {
    this.subscriptionService.unsubscribeAll();
  }

  private _podeSolicitarRetorno() {
    this.podeSolicitarRetorno = this.appService.safraSelecionadaEhVigente();
    if (this.podeSolicitarRetorno == true) {
      let safraEscolhida = JSON.parse(window.localStorage.getItem('safraEscolhida'));
      this.services.get(`UpCertificacao/listastatus/${this.unidadeProdutiva.id}/${safraEscolhida.id}`).subscribe(res => {
        let existeEtapaPcncEmAndamento = res.filter(x => x.descricao == EtapaABR.PCNC_EM_ANDAMENTO);
        this.podeSolicitarRetorno = existeEtapaPcncEmAndamento.length > 0;

        if (this.podeSolicitarRetorno) {
          let resfilter = res.filter(x => x.id != 27);
          let lastItem = resfilter.pop();
          this.etapaAtual = lastItem;

          this.podeSolicitarRetorno = this.mapaStatusSolicitacaoRetorno.some((etapa) => this.etapaAtual.descricao === etapa);
        }

      })
    }
  }

  preencherPcnc() {
    if (!this.podeAcessarFormularioPcnc) {
      this.spinnerService.show();
      this.pcncService.buscarFormulario(this.unidadeProdutiva).subscribe(
        (res) => {
          this.spinnerService.hide();
          window.open(res?.url + res?.hash, "_blank");
        },
        (err) => {
          Sentry.captureException(
            "Erro ao acessar o formulário, verificar se URL do formulário está correto."
          );
          this.toastService.showToast(
            ToastType.DANGER,
            "Error",
            "Erro ao acessar o formulário, favor entrar em contato com a ABRAPA."
          );
          this.spinnerService.hide();
        }
      );
    }
  }

  reenviarEmail() {
    this.spinnerService.show();
    const subscription = this.pcncService
      .reenviarEmail(this.unidadeProdutiva)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe((res) => {
        this.toastService.showToast(ToastType.SUCCESS, "Success", res.message);
      });

    this.subscriptionService.add(subscription);
  }

  private _carregarStatusPcnc() {
    this.loadingStatus = true;
    const subscription = this.pcncService
      .getStatusSafraSelecionada(this.unidadeProdutiva)
      .pipe(
        finalize(() => {
          this.loadingStatus = false;
        })
      )
      .subscribe((statusPcnc) => {
        this.statusPcnc = statusPcnc;
        this._recuperaSafraVigente();
        this._podeReenviarEmail();
        this._podeAcessarPcncPeloStatus();
        this.atualizaStatusPodeConcluirPcnc();
        this._podePularEtapaPeloStatus();
        this._podeAcessarFormularioPeloStatus();
      });

    this.subscriptionService.add(subscription);
  }

  private _podeReenviarEmail() {
    this.pcncService
      .getStatusSafraSelecionadaEnvioEmail(this.unidadeProdutiva)
      .subscribe((statusPcncEnvioEmail) => {
        this.podeReenviarEmail =
          this.safraVigente &&
          (statusPcncEnvioEmail.etapa === EtapaABR.PCNC_CONCLUIDO ||
            statusPcncEnvioEmail.etapa === EtapaABR.PCNC_EM_ANDAMENTO ||
            statusPcncEnvioEmail.etapa === EtapaABR.VDP_CONCLUIDA);
      });
  }

  private _podeAcessarPcncPeloStatus() {
    this.podeAcessarPcnc =
      !this.safraVigente ||
      (this.safraVigente &&
        this.statusPcnc.etapa === EtapaABR.PCNC_EM_ANDAMENTO);
  }

  private _podeAcessarFormularioPeloStatus() {
    const PodeAcessarPcnc =
      this.statusPcnc.etapa === EtapaABR.NAO_INICIADO ||
      this.statusPcnc.etapa === EtapaABR.PCNC_AUSENTE_DE_NAO_CONFORMIDADES ||
      this.statusPcnc.etapa === EtapaABR.VDP_OPTOU_PULAR_VDP ||
      this.statusPcnc.pulouEtapa === true ||
      this.statusPcnc.ausenteNaoConformidade === true;
    this.podeAcessarFormularioPcnc = PodeAcessarPcnc;
  }

  private _podePularEtapaPeloStatus() {
    this.podePularEtapaPCNC =
      this.safraVigente && this.statusPcnc.etapa === EtapaABR.PCNC_EM_ANDAMENTO;
  }

  private _recuperaSafraVigente() {
    this.safraVigente = this.appService.safraSelecionadaEhVigente();
  }

  private atualizaStatusPodeConcluirPcnc() {
    this.podeConcluirPcnc = this.safraVigente && this.validaConcluirPcnc;
  }

  get validaConcluirPcnc(): boolean {
    return (
      this.statusPcnc.etapa === EtapaABR.PCNC_CONCLUIDO ||
      this.statusPcnc.etapa === EtapaABR.PCNC_AUSENTE_DE_NAO_CONFORMIDADES
    );
  }

  concluirPcnc() {
    if (!this.podeConcluirPcnc) {
      return false;
    }
    const subscription = this.alertModalService
      .showConfirm(
        "Alerta",
        "Você confirma que esta Unidade Produtiva realizará o processo de Certificação (VCP)?",
        "Concluir",
        "Cancelar"
      )
      .subscribe((confirm) => {
        if (confirm)
        {
          const modalRef = this.modalService.open(
            ModalSelecionarCertificadoraComponent,
            {
              backdrop: "static",
              keyboard: false,
            }
          );
          modalRef.componentInstance.unidadeProdutiva = this.unidadeProdutiva;
          modalRef.result.then((x) => {
            const subscription = this.pcncService
              .concluir(this.unidadeProdutiva)
              .pipe(
                finalize(() => {
                  this.spinnerService.hide();
                })
              )
              .subscribe((e) => {
                this.toastService.showToast(
                  ToastType.SUCCESS,
                  "Sucesso",
                  "Etapa PCNC Concluída."
                );
                this._carregarStatusPcnc();
              });
          })
        }
      });

    this.subscriptionService.add(subscription);
  }

  unidadeNaoRealizaraVCP() {
    if (!this.podeConcluirPcnc) {
      return false;
    }

    const subscription = this.alertModalService
      .showConfirm(
        "Alerta",
        "Você confirma que esta Unidade Produtiva não realizará o processo de Certificação (VCP)?",
        "Concluir",
        "Cancelar"
      )
      .subscribe((confirm) => {
        if (confirm) {
          this.spinnerService.show();
          const subscription = this.pcncService
            .pcncRecusouVCP(this.unidadeProdutiva)
            .pipe(
              finalize(() => {
                this.spinnerService.hide();
              })
            )
            .subscribe(() => {
              this.toastService.showToast(
                ToastType.SUCCESS,
                "Sucesso",
                "Etapa VCP recusada"
              );
              this._carregarStatusPcnc();
              this.concluirEtapa.emit();
            });

          this.subscriptionService.add(subscription);
        }
      });
    this.subscriptionService.add(subscription);
  }

  unidadeRealizaraVCP() {
    this.pcncService
      .concluir(this.unidadeProdutiva)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe(() => {
        this.toastService.showToast(
          ToastType.SUCCESS,
          "Sucesso",
          "Etapa PCNC concluída"
        );
        this._carregarStatusPcnc();
        this.concluirEtapa.emit();
      });
  }

  pularPCNC() {
    const modalRef = this.modalService.open(
      ModalSelecionarCertificadoraComponent,
      {
        backdrop: "static",
        keyboard: false,
      }
    );
    modalRef.componentInstance.unidadeProdutiva = this.unidadeProdutiva;
    modalRef.result.then(() => {
      this.pcncService
        .pularPCNC(this.unidadeProdutiva)
        .pipe(
          finalize(() => {
            this.spinnerService.hide();
          })
        )
        .subscribe(() => {
          this.toastService.showToast(
            ToastType.SUCCESS,
            "Sucesso",
            "Etapa PCNC pulada"
          );
          
          this._carregarStatusPcnc();
          this.concluirEtapa.emit();
        });
    });
  }
}