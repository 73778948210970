import { FormBuilder, FormGroup } from '@angular/forms';
import { UpCertificacaoService } from 'src/app/services/up-certificacao.service';
import { Component, Input, OnInit, Output } from '@angular/core';
import { UnidadeProdutiva } from 'src/app/entities/unidade-produtiva';
import { EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'modal-upload-licenca-bci',
  templateUrl: './modal-upload-licenca-bci.component.html',
  styleUrls: ['./modal-upload-licenca-bci.component.css']
})
export class ModalUploadLicencaBciComponent implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;

  @Output() emitido = new EventEmitter();
  uploadForm: FormGroup;

  constructor(private upCertificacaoService: UpCertificacaoService, private formBuilder: FormBuilder) { }

  arquivo: any;
  ngOnInit(): void {

    this.uploadForm = this.formBuilder.group({
      licencaBci: ['']
    });
  }

  fazerUpload(modal) {
    if (!!this.arquivo) {
      this.upCertificacaoService.uploadLicencaBci(this.unidadeProdutiva, this.arquivo)
      .pipe(
        finalize(() => {
          this.emitido.emit();
          this.ngOnInit();
        })
      )
      .subscribe();
    }

    this.arquivo = undefined;
    modal.close();
  }

  handleFileInput(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.arquivo = reader.result;
      };
    }

  }

}
