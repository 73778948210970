import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { EtapasEnum } from '../etapas-enum';
import { InformacaoEnum } from './informacao-enum';
import { SolicitarRetornoEtapaService } from 'src/app/services/solicitar-retono-etapa.service';
import { ToastService } from 'src/app/services/toast.service';
import { ToastType } from 'src/app/entities/toast';
import { SpinnerService } from 'src/app/services/spinner.service';
import { AlertModalService } from 'src/app/services/alert-modal.service';
import { map } from 'rxjs/operators';
import { ConfirmacaoRetornoEnum } from './confirmacao-retorno-enum';

@Component({
    selector: 'modal-solicitar-retorno',
    templateUrl: './modal-solicitar-retorno.component.html',
    styleUrls: ['./modal-solicitar-retorno.component.css']
})
export class ModalSolicitarRetornoComponent implements OnInit {
    @Input() etapa: string;
    @Input() etapaAtual: any;
    @Input() habilitarSolicitacao: boolean;
    @Output() recarregarSolicitacao = new EventEmitter();
    spinnerVisibility: boolean = false;
    informacao: string = '';
    etapaDesejada: string = '';
    iconInfo = faInfoCircle;
    modalRef: NgbModalRef;

    formSolicitacaoRetorno = this.fb.group({
        motivo: ['', Validators.required],
        idStatusCertificacaoAtual: [0, Validators.required],
        idEtapaCertificacaoDesejado: [0, Validators.required]
    })

    constructor(
        private modalService: NgbModal,
        public activeModal: NgbActiveModal,
        private toastService: ToastService,
        private solicitacaoServices: SolicitarRetornoEtapaService,
        private fb: FormBuilder,
        private spinnerService: SpinnerService,
        private alertService: AlertModalService) { }

    ngOnInit(): void {
    }

    open(content) {
        this.modalRef = this.modalService.open(content, { size: 'md', centered: true });
        this.atribuirValores();
    }

    atribuirValores() {
        this.informacao = InformacaoEnum[this.etapa]
        this.formSolicitacaoRetorno.controls.idStatusCertificacaoAtual.setValue(this.etapaAtual.idStatusCertificacao);
        this.buscaEtapaDesejada();
    }
    buscaEtapaDesejada() {
        let idEtapaDesejada = 0;
        if (this.etapa === 'VDP') {
            idEtapaDesejada = EtapasEnum['VDP - Em andamento'];
        }

        if (this.etapa === 'VCP') {
            idEtapaDesejada = EtapasEnum['VCP - Em andamento'];
        }
        if (this.etapa === 'emissão do Certificado') {
            idEtapaDesejada = EtapasEnum['Certificado em emissão'];
        }

        if (this.etapa === 'PCNC') {
            this.solicitacaoServices
                .verificarSeReprovouNoVDB(this.etapaAtual.idUpCertificacao)
                .subscribe(
                    (data) => {
                        if (data) {
                            idEtapaDesejada = EtapasEnum['PCNC - Em andamento'];
                        } else {
                            idEtapaDesejada = EtapasEnum['PCNC - Ausente de Não Conformidades'];
                        }
                        this.formSolicitacaoRetorno.controls.idEtapaCertificacaoDesejado.setValue(idEtapaDesejada);
                        this.etapaDesejada = EtapasEnum[idEtapaDesejada];
                    },
                    (err) => {
                        this.toastService.showToast(ToastType.DANGER, 'Error', err);
                    }
                );
        }

        this.formSolicitacaoRetorno.controls.idEtapaCertificacaoDesejado.setValue(idEtapaDesejada);
        this.etapaDesejada = EtapasEnum[idEtapaDesejada];
    }

    submit() {
        this.alertService.showConfirm(
            '',
            `${ConfirmacaoRetornoEnum[this.etapa]}`
        ).pipe(
            map((confirmed) => {
                if (confirmed) {
                    this.spinnerService.show();
                    this.solicitacaoServices.post(this.formSolicitacaoRetorno.value).subscribe(res => {
                        this.recarregarSolicitacao.emit();
                        this.closeModal();
                    }, (err) => {
                        this.closeModal();
                    })
                }
            })
        ).subscribe();
    }

    closeModal() {
        this.modalRef.close();
        this.spinnerService.hide();
    }
}
