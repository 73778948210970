<div *ngIf="podeAcessarVcp" class="alert alert-info" role="alert">
  <fa-icon [icon]="iconInfo" size="sm"></fa-icon>&nbsp;Não compartilhe o link de acesso à lista VCP. Pessoas com acesso a este link poderão preencher o formulário indevidamente.
</div>
<div class="row">
  <div class="col-lg-3">
    <titulo-modal>Formulário VCP</titulo-modal>

    <app-external-link-btn [disabled]="!podeAcessarVcp" (click)="preencherVcp()">Acessar VCP</app-external-link-btn>

    <div class="d-flex align-items-center mt-3">

      <ativador-tooltip [isBtn]="true" label="Resumo da VCP (%) " tooltipText="O formulário ainda não foi enviado"
        (action)="open(content)">
      </ativador-tooltip>
    </div>
  </div>
  <div class="col-lg-7">
    <app-status-convite title="Status" [status]="status"></app-status-convite>
  </div>
  <div class="col-lg-2 mb-4 mt-2 mt-lg-0" *ngIf="podeVerOutrasOpcoes">
    <dropdown-btn labelAtivadora="Outras opções">
      <dropdown-btn-item (actionPerformed)="reenviarEmail()" *ngIf="podeReenviarEmail">Reenviar e-mail VCP</dropdown-btn-item>
      <dropdown-btn-item (actionPerformed)="open(nota)" *ngIf="podeVerNotaExplicativa">
        Acessar <strong>Nota Explicativa</strong>
      </dropdown-btn-item>
      <dropdown-btn-item *ngIf="podeSolicitarRetorno">
        <modal-retorno [etapa]="'VCP'" [etapaAtual] ="etapaAtual" [unidadeProdutiva]="unidadeProdutiva"></modal-retorno>
      </dropdown-btn-item>
    </dropdown-btn>
  </div>
</div>
<div class="row mt-4" *ngIf="true">
  <div class="col-12 d-flex justify-content-end">
    <ng-container>
      <button [disabled]="podeReprovarVcp" (click)="ConcluirVcpUnidadeReprovada()"
        class="col-5 btn btn-sm btn-danger mr-2">
        Encerrar processo com a Unidade Produtiva Reprovada</button>
    </ng-container>
    <button [disabled]="podeFinalizarVcp" (click)="ConcluirVcpFinalizarProcesso()" class="col-5 btn btn-sm btn-success">
      Encerrar processo com a Unidade Produtiva Aprovada
    </button>
  </div>
</div>

<ng-template #content let-modal>
  <app-modal-etapa-vcp-nota [containerNota]="containerNota" [unidadeProdutiva]="unidadeProdutiva">
  </app-modal-etapa-vcp-nota>
</ng-template>
<ng-template #nota let-modal>
  <app-modal-vdp-nota-explicativa [modal]="modal" *ngIf="!notaExplicativa" [unidadeProdutiva]="unidadeProdutiva">
  </app-modal-vdp-nota-explicativa>
  <app-modal-vdp-nota-explicativa-preenchida [modal]="modal" *ngIf="notaExplicativa"
    [unidadeProdutiva]="unidadeProdutiva">
  </app-modal-vdp-nota-explicativa-preenchida>
</ng-template>
