<div class="row">
  <div *ngIf="setor('CONVITES') | podeVerSetorAndamentoPipe" class="col-lg-4 mb-lg-4">
    <app-status-convite [loading]="loadingStatusConvite" title="Convite" [shimmerAmount]="2" [status]="statusConvite">
    </app-status-convite>
    <app-external-link-btn *ngIf="(setor('TERMO_ADESAO') | podeVerSetorAndamentoPipe) && !podeAcessarTermoAdesao" [isBtn]="false"
      (click)="buscaTermoDeAdesao()">Acessar termo de adesão</app-external-link-btn>
  </div>

  <div *ngIf="setor('VDP') | podeVerSetorAndamentoPipe" class="col-lg-4 mt-4 mt-lg-0">
    <app-status-convite [loading]="loadingStatusVdp" title="VDP" [status]="statusVdp"></app-status-convite>
      <app-external-link-btn *ngIf="(setor('VDP_BTN_ACESSO') | podeVerSetorAndamentoPipe) && podeAcessarVdp"  [isBtn]="false"
        (click)="buscarFormulario('VDP')">Acessar VDP</app-external-link-btn>
  </div>

  <div *ngIf="setor('PCNC') | podeVerSetorAndamentoPipe" class="col-lg-4 mt-4 mt-lg-0">
    <app-status-convite [loading]="loadingStatusPcnc" title="PCNC" [status]="statusPcnc"></app-status-convite>
    <app-external-link-btn *ngIf="(setor('PCNC_BTN_ACESSO') | podeVerSetorAndamentoPipe) && podeAcessarPcnc" [isBtn]="false"
      (click)="buscarFormulario('PCNC')">Acessar PCNC</app-external-link-btn>
  </div>

  <div *ngIf="setor('VCP') | podeVerSetorAndamentoPipe" class="col-lg-4 mt-4 mt-lg-0">
    <app-status-convite [loading]="loadingStatusVcp" title="VCP" [status]="statusVcp"></app-status-convite>
    <app-external-link-btn *ngIf="podeAcessarVcp" [isBtn]="false" (click)="buscarFormularioVCP()">Acessar VCP
    </app-external-link-btn>

  </div>

  <div *ngIf="setor('ABR') | podeVerSetorAndamentoPipe" class="col-lg-4 mt-4 mt-lg-0">
    <app-status-convite [loading]="loadingStatusAbr" title="Finalização do certificado ABR"
      [status]="andamentoFinalizacaoAbr?.status"></app-status-convite>
    <app-label-value [loading]="loadingStatusAbr" label="Nome da Certificadora"
      [hide]="!andamentoFinalizacaoAbr?.nomeCertificadora" [value]="andamentoFinalizacaoAbr?.nomeCertificadora">
    </app-label-value>
    <app-label-value [loading]="loadingStatusAbr" label="Número do Certificado"
      [hide]="!andamentoFinalizacaoAbr?.numeroCertificado" [value]="andamentoFinalizacaoAbr?.numeroCertificado">
    </app-label-value>
    <div class="row mt-1">
      <div class="col-12">
      </div>
      <div *ngIf="setor('DOWNLOAD_CERT_ABR') | podeVerSetorAndamentoPipe" class="col-12">
        <ativador-tooltip [isBtn]="false" *ngIf="podeAcessarCertificadoAbr" (action)="downloadCertificadoAbr()" label="Download do certificado ABR">
        </ativador-tooltip>
      </div>
    </div>
  </div>

  <div *ngIf="setor('BCI') | podeVerSetorAndamentoPipe" class="col-lg-4 mt-4 mt-lg-0">
    <app-status-convite [loading]="loadingStatusBci" title="Licença BCI" [status]="andamentoLicencaBci?.status">
    </app-status-convite>
    <app-label-value [loading]="loadingStatusBci" label="Licenciamento BCI"
      [hide]="!andamentoLicencaBci?.numeroCertificado" [value]="andamentoLicencaBci?.numeroCertificado">
    </app-label-value>
    <ativador-tooltip *ngIf="(setor('DOWNLOAD_LICENCA_BCI') | podeVerSetorAndamentoPipe) && podeAcessarDownloadBci"
      [isBtn]="false" (action)="downloadLicencaBci()" label="Download da licença BCI">

    </ativador-tooltip>
  </div>
</div>