import { OnInit } from "@angular/core";
import { finalize } from "rxjs/operators";
import { ContainerNota, StatusNota } from "src/app/entities/NotaCertificacao";
import { Status } from "src/app/entities/status";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";

import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  faExternalLinkAlt,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { UnidadeProdutivaStatusComponent } from "../../../app/components/unidade-produtiva-status/unidade-produtiva-status.component";
import { EtapaABR } from "../../entities/etapas";
import { AlertModalService } from "../../services/alert-modal.service";
import { SpinnerService } from "../../services/spinner.service";
import { ToastService } from "../../services/toast.service";
import { VdpService } from "../../services/vdp.service";
import { AppService } from "src/app/services/app-service";
import { ToastType } from "src/app/entities/toast";
import { NotaService } from "src/app/services/nota.service";
import { NotaExplicativaService } from "src/app/nota-explicativa.service";
import * as Sentry from "@sentry/angular";
import { ModalSelecionarCertificadoraComponent } from "../modal-certificadora/modal-selecionar-certificadora/modal-selecionar-certificadora.component";
import { Services } from "src/app/services/service";

@Component({
  selector: "modal-etapa-vdp",
  templateUrl: "./modal-etapa-vdp.component.html",
})
export class ModalEtapaVdp implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Output() concluirEtapa = new EventEmitter();
  usuarioLogado: any;
  status: Status;
  icon = faExternalLinkAlt;
  iconInfo = faInfoCircle;
  notaExplicativa = false;
  habilitarAcessarVdp = false;
  etapaAtual: any;
  unidadeProdutivaStatus = UnidadeProdutivaStatusComponent;

  safraVigenteAtivaStatusVDPEmAndamentoCompleto = false;

  containerNota: ContainerNota;
  loadingNotaVdp: boolean;

  notaAtingida: any;

  loadingStatus = true;
  loadingButton = false;

  mapaStatusPodePular: EtapaABR[] = [EtapaABR.VDP_ANDAMENTO];

  mapaStatusExbirNota: EtapaABR[] = [
    EtapaABR.VDP_ANDAMENTO,
    EtapaABR.VDP_CONCLUIDA,
    EtapaABR.VDP_OPTOU_PULAR_VDP,
  ];

  mapaStatusSolicitacaoRetorno: EtapaABR[] = [
    EtapaABR.PCNC_EM_ANDAMENTO,
    EtapaABR.PCNC_AUSENTE_DE_NAO_CONFORMIDADES,
    EtapaABR.PCNC_RECUSOU_VCP,
    EtapaABR.PCNC_OPTOU_PULAR_PCNC,
  ];

  podeSolicitarRetorno: boolean = false;

  constructor(
    private appService: AppService,
    private vdpService: VdpService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private services: Services,
    private spinnerService: SpinnerService,
    private alertService: AlertModalService,
    private notaService: NotaService,
    private notaExplicativaService: NotaExplicativaService
  ) {
    this.containerNota = this.notaService.iniciarContainerNota();
  }

  ngOnInit(): void {
    this.loading();
  }

  loading() {
    this.recuperaUsuarioLogado();
    this.carregaStatusVdp();
    this._carregarStatusNotaVDP();
    this._podeSolicitarRetorno();
    this.verificaNotaExplicativa();
  }

  private recuperaUsuarioLogado() {
    this.usuarioLogado = JSON.parse(window.localStorage.getItem("usuario"));
  }

  private verificaNotaExplicativa() {
    this.notaExplicativaService
      .verificaNotaExplicativa(this.unidadeProdutiva)
      .subscribe((res) => (this.notaExplicativa = res));
  }

  private carregaStatusVdp() {
    this.vdpService
      .getStatusSafraSelecionada(this.unidadeProdutiva)
      .pipe(
        finalize(() => {
          this.loadingStatus = false;
        })
      )
      .subscribe((status) => {
        this.status = status;
        this.habilitarAcessarVdp =
          EtapaABR.VDP_OPTOU_PULAR_VDP === this.status.etapa;

        const safraVigenteAtiva = this.appService.safraSelecionadaEhVigente();
        this.safraVigenteAtivaStatusVDPEmAndamentoCompleto =
          safraVigenteAtiva && this.status.etapa === EtapaABR.VDP_ANDAMENTO;
      });
  }

  get podePularVdp(): boolean {
    if (!this.status) {
      return false;
    }
    return this.mapaStatusPodePular.some(
      (etapa) => this.status.etapa === etapa
    );
  }

  get exibirNotaExplicativa(): boolean {
    if (!this.status) {
      return false;
    }
    return this.mapaStatusExbirNota.some(
      (etapa) => this.status.etapa === etapa
    );
  }

  get exibirToolTip(): boolean {
    return !this.containerNota.temNota;
  }

  preencherVdp() {
    if (!this.habilitarAcessarVdp) {
      var windowReference = window.open();
      this.loadingButton = true;

      this.vdpService.buscarFormulario(this.unidadeProdutiva).subscribe(
        (x) => {
          const url = x?.url + x?.hash;
          windowReference.location.href = url;
        },
        (err) => {
          Sentry.captureException(
            "Erro ao acessar o formulário, verificar se URL do formulário está correto."
          );
          this.toastService.showToast(
            ToastType.DANGER,
            "Error",
            "Erro ao acessar o formulário, favor entrar em contato com a ABRAPA."
          );
        },
        () => {
          this.loadingButton = false;
        }
      );
    }
  }

  pularEtapaVdp() {
    this.alertService
      .showConfirm(
        "Confirma Pular etapa VDP?",
        "Uma vez realizada esta ação, os formulários de VDP e PCNC não serão liberados para o preenchimento, liberando esta Unidade Produtiva diretamente para aplicação de VCP!"
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          const modalRef = this.modalService.open(
            ModalSelecionarCertificadoraComponent,
            {
              backdrop: "static",
              keyboard: false,
            }
          );
          modalRef.componentInstance.unidadeProdutiva = this.unidadeProdutiva;
          modalRef.result.then(() => {
            this.vdpService
              .pularEtapaVdp(this.unidadeProdutiva)
              .subscribe((x) => {
                this.toastService.showToast(
                  ToastType.SUCCESS,
                  "Sucesso",
                  x.message
                );
                this.concluirEtapa.emit();
                this.loading();
              });
          });
        }
      });
  }

  concluirEtapaVdp() {
    this.alertService
      .showConfirm(
        "Confirmar concluir etapa VDP?",
        "Confirmar o encerramento da etapa VDP, liberando a UP apenas para o processo de PCNC? Uma vez realizada esta ação, não será mais permitido realizar alterações no formulário de VDP e não será permitido também que a UP realize o processo de VCP e Certificação!"
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          const modalRef = this.modalService.open(
            ModalSelecionarCertificadoraComponent,
            {
              backdrop: "static",
              keyboard: false,
            }
          );
          modalRef.componentInstance.unidadeProdutiva = this.unidadeProdutiva;
          modalRef.result.then((x) => {
            this.spinnerService.show();
            this.vdpService.encerrarVdp(this.unidadeProdutiva).subscribe(() => {
              this.spinnerService.hide();
              this.toastService.showToast(
                ToastType.SUCCESS,
                "Sucesso",
                "VDP concluída com sucesso."
              );
              this.loading();
              this.concluirEtapa.emit();
            });
          });
        }
      });
  }

  concluirVDP() {
    this.vdpService.concluirEtapaVDP(this.unidadeProdutiva).subscribe(() => {
      this.toastService.showToast(
        ToastType.SUCCESS,
        "Sucesso",
        "Etapa VDP concluda com sucesso"
      );
      this.loading();
      this.concluirEtapa.emit();
    });
  }

  acessarNotaExplicativa() {
    // TODO: Verificar como acessar nota explicativa
    // window.open(x?.url + x?.hash, "_blank");
  }

  open(content) {
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  private _carregarStatusNotaVDP() {
    this.loadingNotaVdp = true;
    this.vdpService
      .getNotasSafraSelecionada(this.unidadeProdutiva)
      .pipe(finalize(() => (this.loadingNotaVdp = false)))
      .subscribe((res) => {
        this.containerNota = res;
      });

    if (this.containerNota.statusNota === StatusNota.NAO_INICIADO) {
      this.containerNota = this.notaService.iniciarContainerNota();
    }
  }

  private _podeSolicitarRetorno() {
    this.podeSolicitarRetorno = this.appService.safraSelecionadaEhVigente();
    if (this.podeSolicitarRetorno == true) {
      let safraEscolhida = JSON.parse(
        window.localStorage.getItem("safraEscolhida")
      );
      this.services
        .get(
          `UpCertificacao/listastatus/${this.unidadeProdutiva.id}/${safraEscolhida.id}`
        )
        .subscribe((res) => {
          let resfilter = res.filter((x) => x.id != 27 && x.id != 6);
          let lastItem = resfilter.pop();
          this.etapaAtual = lastItem;

          this.podeSolicitarRetorno = this.mapaStatusSolicitacaoRetorno.some(
            (etapa) =>
              this.etapaAtual.descricao.toUpperCase() === etapa.toUpperCase()
          );
        });
    }
  }

  reenviarEmail() {
    this.spinnerService.show();
    this.vdpService
      .reenviarEmailVDP(this.unidadeProdutiva)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe((res) => {
        this.toastService.showToast(ToastType.SUCCESS, "Success", res.message);
      });
  }
}
