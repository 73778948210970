import { finalize } from "rxjs/operators";

import { Component, EventEmitter, Input, Output } from "@angular/core";

import { ContainerNota, MetaNota, Nota } from "../../../entities/NotaCertificacao";
import { UnidadeProdutiva } from "../../../entities/unidade-produtiva";
import { VdpService } from "../../../services/vdp.service";

@Component({
  selector: "modal-etapa-vdp-nota",
  templateUrl: "./modal-etapa-vdp-nota.component.html",
  styleUrls: ["./modal-etapa-vdp-nota.component.css"],
})
export class ModalEtapaVdpNotaComponent {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() containerNota: ContainerNota;

  // data: Nota[] = [];
  // meta: MetaNota;
  loadingNotas = true;
  @Output()  statusResposta = new EventEmitter();

  constructor(public vdpService: VdpService) {}

  ngOnInit(): void {
    // this.vdpService
    //   .getNotas(this.unidadeProdutiva.id)
    //   .pipe(
    //     finalize(() => {
    //       this.loadingNotas = false;
    //     })
    //   )
    //   .subscribe(({ data, meta }) => {
    //     this.data = data;
    //     this.meta = meta;
    //     this.falhaNoRetorno(false);
    //   },
    //   error => {
    //     this.falhaNoRetorno(true);
    //   });
  }

  falhaNoRetorno(status: boolean) {
    this.statusResposta.emit(status);
  }
}
