import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

export type SortDirection = "ASC" | "DESC";

const directionIconMap = {
  ASC: faSortUp,
  DESC: faSortDown,
};

@Component({
  selector: "app-sortable-th",
  templateUrl: "./sortable-th.component.html",
  styleUrls: ["./sortable-th.component.css"],
})
export class SortableThComponent implements OnInit, OnChanges {
  @Output()
  sortDirectionChanged = new EventEmitter<SortDirection>();

  sortIcon = faSort;
  @Input() direction: SortDirection = null;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(_: SimpleChanges): void {
    this._defineIcon();
  }

  private _getNewDirection() {
    if (this.direction === null) {
      return "ASC";
    }

    if (this.direction === "ASC") {
      return "DESC";
    }

    return null;
  }

  private _defineIcon() {
    this.sortIcon = directionIconMap[this.direction] ?? faSort;
  }

  changeSortDirection() {
    const newDirection = this._getNewDirection();

    this.sortDirectionChanged.emit(newDirection);
  }
}
