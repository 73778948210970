import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContainerNota } from 'src/app/entities/NotaCertificacao';
import { ToastType } from 'src/app/entities/toast';
import { UnidadeProdutiva } from 'src/app/entities/unidade-produtiva';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-exibir-vcp',
  templateUrl: './exibir-vcp.component.html',
  styleUrls: ['./exibir-vcp.component.css']
})
export class ExibirVcpComponent implements OnInit {

  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() loading: boolean;
  @Input() containerNota: ContainerNota;

  constructor(
    private modalService: NgbModal,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  get statusAprovacao() {
    if(!this.containerNota.temNota) {
      return false;
    }
    return this.containerNota.meta.aprovado;
  }

  open(content) {
    if(!this.containerNota.temNota) {
      return this.toastService.showToast(ToastType.INFO, "Nota VCP", "Etapa VDP não iniciada");
    }
    this.modalService.open(content, { centered: true, size: 'lg' });
  }
}
