import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { APP_API, REDIRECIONAMENTO_EXTERNO } from '../app.api';
import { JwtToken } from '../entities/JwtToken';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  [x: string]: any;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
      'Access-Control-Allow-Headers': 'Content-Type'
    })
  };

  constructor(private http: HttpClient) { }

  private authToken: JwtToken = new JwtToken();
  private authorized = false;

  login(idUsuario: string, token: string): Observable<any[]> {
    return this.http.post<any[]>(`${APP_API}/Account/LoginUrl`, { idUsuario, token }, this.httpOptions)
      .pipe(
        tap(res => {
          this.setToken(res);
          this.setAuthorized(true);
        })
      );
  }

  logout() {
    this.setAuthorized(false);
    this.authToken.limpar();
    window.localStorage.clear();
  }

  logoutWithError() {
    this.logout();
    window.location.href = REDIRECIONAMENTO_EXTERNO  + "?Logout=true";
  }

  hasToken(): boolean {
    return this.authToken.hasToken();
  }

  getToken(): JwtToken {
    return this.authToken;
  }

  isAuthorized() {
    return this.authorized;
  }

  private setToken(authToken) {
    this.setAuthorized(true);
    this.authToken.setToken(
      authToken.token,
      authToken.expires,
      authToken.type
    );
  }

  setAuthorized(isAuthorized) {
    this.authorized = isAuthorized;
  }

  addAuthenticationToken(request: HttpRequest<any>) {
    const token = this.getTokenLocal();

    if (request.url.includes(APP_API))
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
      });
    return request;
  }

  getTokenLocal() {
    return window.localStorage.getItem('token');
  }
}
