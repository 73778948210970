<div class="container-fluid p-3" style="background-color: #fff">
  <div class="mb-4">
    <p class="h4"><strong>
        Nota Explicativa
      </strong></p>
    <p class="text-justify pr-2 pl-2">
      A Nota Explicativa é utilizada em ocasiões em que critérios das listas VDP e VCP da Unidade Produtiva que está
      em
      processo de certificação são verificados em outra Unidade Produtiva que, geralmente, serve de sede para esta.
    </p>
  </div>
  <div class="mb-4">
    <p class=" h5 text-muted">
      Unidade Produtiva que receberá a Nota Explicativa
    </p>
    <p class="h5 pl-2"><strong>
        {{unidadeProdutiva.nomeUnidadeProdutiva}}
      </strong></p>
  </div>
  <div class="mb-4">

    <div class="pl-2">
      <p class="h6 font-color">
        <strong> 1- Selecione a Unidade Produtiva Sede</strong>
      </p>
      <label>Selecione a sede</label>
      <select #sede (change)="selectHandler($event)" class="form-control form-control-sm mb-2"
        style="width: fit-content;">
        <option selected disabled>Selecione...</option>
        <option *ngFor="let i of fazendasAssociadas" [title]="i.descricao" [value]="i.id">
          {{i.descricao | slice:0:105}}
        </option>
      </select>
      <p class="text-muted mb-2">
        Unidade Produtiva selecionada como sede.
      </p>
      <p *ngIf="selectedSede" class="h6"> <strong>{{ selectedSede.descricao | slice:0:195}}</strong></p>
    </div>

  </div>
  <div class="mb-4">
    <p class="h6 font-color">
      <strong> 2- Selecione o(s) critério(s) auditado(s) na Unidade Produtiva</strong>
    </p>

    <form [formGroup]="form" class="form-group">
      <div class="form-check" *ngFor="let order of ordersArray.controls; let i = index">
        <span class="d-inline-block btn-sm">
          <label [for]="i" formArrayName="orders" class="form-check-label">
            <input type="checkbox" [id]="i" class="form-check-input" [formControlName]="i">
            {{data[i].descricao}}
          </label>
        </span>
      </div>
    </form>
  </div>
  <div class="mb-4">
    <p class="h6 font-color">
      <strong>
        3- Pré-visualize a Nota Explicativa e confirme as informações
      </strong>
    </p>
    <button [disabled]="!liberarDownload" (click)="downloadNotaExplicativa()" class="btn btn-sm btn-download d-flex"
      style="width: fit-content;">
      Download da Nota Explicativa
    </button>
  </div>

  <div class="row justify-content-end mr-2">

    <div class="">
      <button (click)="close()" class="btn btn-sm btn-light m-2">Cancelar</button>
    </div>
    <div>
      <button [disabled]="!liberarDownload" (click)="submit()" class="btn btn-sm m-2 btn-primary">
        Confirmar e Salvar Nota Explicativa
      </button>
    </div>
  </div>
</div>