import { ToastService } from "./../../services/toast.service";
import { ResponsavelAbrService } from "src/app/services/responsavel-abr.service";
import { Component, OnInit } from "@angular/core";
import { UpCertificacaoService } from "src/app/services/up-certificacao.service";
import { ToastType } from "src/app/entities/toast";
import { GestaoAbrapaService } from "src/app/services/gestao-abrapa.service";
import * as moment from 'moment';

@Component({
  selector: "modal-data-limite-bci",
  templateUrl: "./modal-data-limite-bci.component.html",
  styleUrls: ["./modal-data-limite-bci.component.css"],
})
export class ModalDataLimiteBciComponent implements OnInit {
  constructor(
    private upCertificacaoService: UpCertificacaoService,
    private toastService: ToastService,
    private gestaoAbrapaService: GestaoAbrapaService
  ) {}

  dateTimeValue = null;

  ngOnInit(): void {}

  definirLimiteBci(dataLimiteBciInput: HTMLInputElement, modal: any) {
    if (!dataLimiteBciInput.value.length) {
      return;
    }

    this.upCertificacaoService
      .definirDataLimiteBci(dataLimiteBciInput.value)
      .subscribe((res) => {
        this.toastService.showToast(
          ToastType.SUCCESS,
          "Data Limite BCi",
          "Data limite BCI  definida"
        );
        modal.close();
      });
  }

  getDataLimiteBci() {
    console.log("passei aqui");
    this.gestaoAbrapaService
      .getDataLimiteEditarConviteBci()
      .subscribe((data) => {
        this.dateTimeValue = moment(data.dataLimiteBci).format('YYYY-MM-DD')
          console.log(this.dateTimeValue)
      });
  }
}
