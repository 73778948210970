<div class="container__fluid p-3" style="background-color: #fff;">
  <p class="h4"><strong>{{title}}</strong></p>
  <div class="vdp-identification row">
    <div class="col-6">
      <p class="text-muted h6">Unidade Produtiva</p>
      <p class="h5"><strong>{{unidadeProdutiva.nomeUnidadeProdutiva | titlecase}}</strong></p>
    </div>
    <div class="col-6">
      <p class="text-muted h6">Safra de certificação</p>
      <p class="h5"><strong>{{ unidadeProdutiva.safraCertificacao}}ª</strong></p>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-md-6">
      <p class="h6">Nota mínima necessária para perguntas de certificação</p>
      <p class="h5">
        <fa-icon style="font-size: 0.5rem;" [icon]="icon" size="sm"></fa-icon>
        &nbsp;<strong>{{containerNota?.meta?.notaMinimaPerguntas || getNotaPorSafra(unidadeProdutiva)}}
          %</strong>
      </p>
    </div>
    <div class=" col-md-6">
      <p class="h6">Nota mínima necessária para perguntas de conformidade obrigatória</p>
      <p class="h5">
        <fa-icon style="font-size: 0.5rem;" [icon]="icon" size="sm"></fa-icon>&nbsp;<strong>100 %</strong>
      </p>
    </div>
  </div>
  <hr>
  <p>
    <strong>
      Nota obtida por Critério
    </strong>
  </p>
  <div class="row">
    <div class="col-2">
        <p class="text-muted">
          Critérios
        </p>
    </div>
    <div class="col-1">
        <p class="text-muted">
          Itens
        </p>
    </div>
    <div class="col-1">
        <p class="text-muted">
          Itens de Certificação
        </p>
    </div>
    <div class="col-1">
        <p class="text-muted">
          Itens (CMP)
        </p>
    </div>
    <div class="col-2">
        <p class="text-muted">
          Itens de certificação
        </p>
    </div>
    <div class="col-2">
        <p class="text-muted">
          Itens de conformidade obrigatória (eliminatória)
        </p>
    </div>
    <div class="col-1">
        <p class="text-muted">
          Itens Reprovados
        </p>
    </div>
    <div class="col-2">
        <p class="text-muted">
          Itens não respondidos
        </p>
    </div>
  </div>
  <div class="row" *ngFor="let i of containerNota.data">
    <div class="col-2 h6"><strong>{{i.criterio || ""}}</strong></div>
    <div class="col-1 h6"><strong>{{i.quantidadeItensTotal ||""}}</strong></div>
    <div class="col-1 h6"><strong>{{i.quantidadeItensCertificacao ||""}}</strong></div>
    <div class="col-1 h6"><strong>{{i.quantidadeItensCMP}}</strong></div>
    <div class="col-2 h6">
        <span [ngClass]="{'dot-green': i.aprovadoPerguntas===true, 'dot-red':i.aprovadoPerguntas === false }">
        </span>
        {{i.porcentagemPerguntas || 0}} %
    </div>
    <div class="col-2 h6"><span
      [ngClass]="{'dot-green': i.aprovadoPerguntasConformidade === true, 'dot-red': i.aprovadoPerguntasConformidade === false }">
      </span> {{i.aprovadoPerguntasConformidade ? 'Aprovado':'Reprovado'}}
    </div>
    <div class="col-1 h6"><strong>
          <span *ngIf="i?.itensReprovados.length <12">{{i.itensReprovados |slice:0:11}}</span>
          <span *ngIf="i?.itensReprovados.length >=12" placement="left"
            [ngbTooltip]="i.itensReprovados">{{i.itensReprovados
      |slice:0:11}}...</span></strong>
    </div>
    <div class="col-2 h6">
      <strong>
        <span *ngIf="i?.itensNaoRespondidos.length <12">
          {{i.itensNaoRespondidos |slice:0:11}}
        </span>
        <span *ngIf="i?.itensNaoRespondidos.length >=12" placement="left" [ngbTooltip]="i.itensNaoRespondidos">
          {{i.itensNaoRespondidos |slice:0:11}}...
        </span>
      </strong>
    </div>
  </div>
  <div *ngIf="!containerNota.temNota || !containerNota.data.length">
    <p>Os Critérios e Itens poderão ser visualizados a medida que o formulário for preenchido e salvo</p>
  </div>
  <hr>
  <div *ngIf="containerNota.data && containerNota.data.length > 0">
    <span class="h5"><strong>Resultado final: <span
          [ngClass]="{'dot-green-large': containerNota.meta.aprovado === true, 'dot-red-large': containerNota.meta.aprovado === false }">
        </span>{{containerNota.meta.aprovado? 'Aprovado':'Reprovado'}} </strong></span>
  </div>
</div>