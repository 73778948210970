<div *ngIf="!podeAcessarFormularioPcnc" class="alert alert-info" role="alert">
  <fa-icon [icon]="iconInfo" size="sm"></fa-icon>&nbsp;Não compartilhe o link de acesso à lista PCNC. Pessoas com acesso a este link poderão preencher o formulário indevidamente.
</div>
<div class="row justify-content-between">
  <div class="col-lg-3 mb-2 mb-lg-0">
    <titulo-modal>Formulário PCNC</titulo-modal>
    <div class="row">
      <div class="col-lg-12">
        <app-external-link-btn  [disabled]="podeAcessarFormularioPcnc" (click)="preencherPcnc()">Acessar PCNC
        </app-external-link-btn>
      </div>
    </div>
  </div>
  <div class="col-lg-7">
    <app-status-convite [loading]="loadingStatus" title="Status" [status]="statusPcnc"></app-status-convite>
  </div>
  <div class="col-lg-2 mb-2 mb-lg-0"  >
    <dropdown-btn labelAtivadora="Outras opções">
      <dropdown-btn-item (actionPerformed)="reenviarEmail()" *ngIf="podeReenviarEmail">Reenviar e-mail PCNC</dropdown-btn-item>
      <dropdown-btn-item [disabled]="!podePularEtapaPCNC" (actionPerformed)="pularPCNC()" *ngIf="podeReenviarEmail">Pular etapa 
        <strong>PCNC</strong>
      </dropdown-btn-item>
      <dropdown-btn-item *ngIf="podeSolicitarRetorno">
        <modal-retorno [etapa]="'PCNC'" [etapaAtual] ="etapaAtual" [unidadeProdutiva]="unidadeProdutiva"></modal-retorno>
      </dropdown-btn-item>
    </dropdown-btn>
  </div>
</div> 

<div class="d-flex justify-content-end" *ngIf="this.statusPcnc">
  <div>
    <button [disabled]="!validaConcluirPcnc" class="btn btn-sm btn-danger m-1" (click)="unidadeNaoRealizaraVCP()">A
      Unidade Produtiva não realizará o processo de Certificação (VCP)</button>&nbsp;
    <button [disabled]="!validaConcluirPcnc" class="btn btn-sm btn-success m-1" (click)="concluirPcnc()">A Unidade
      Produtiva realizará o processo de Certificação (VCP)</button>
  </div>
</div>
<!-- <div class="row justify-content-end">
  <div class="col-12 col-lg-3">
    <ng-template [ngIf]="podeConcluirPcnc">
      <button class="btn btn-sm btn-success form-control" (click)='concluirPcnc()'>Concluir PCNC</button>
    </ng-template>
  </div>
</div> -->
