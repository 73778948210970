import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { finalize } from "rxjs/operators";

import { SpinnerService } from "../../../services/spinner.service";
import { CertificadoraService } from "../../../services/Certificadora.service";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";
import { SubscriptionService } from "../../../services/subscription.service";
import { Certificadora } from "src/app/entities/certificadora";
import { UpCertificacaoService } from "../../../services/up-certificacao.service";

@Component({
  selector: "modal-selecionar-certificadora",
  templateUrl: "./modal-selecionar-certificadora.component.html",
  styleUrls: ["./modal-selecionar-certificadora.component.css"],
})
export class ModalSelecionarCertificadoraComponent
  implements OnInit, OnDestroy
{
  unidadeProdutiva: UnidadeProdutiva;
  certificadoras: Certificadora;
  iconInfo = faInfoCircle;
  certSelecionada= null;

  constructor(
    public activeModal: NgbActiveModal,
    private selecionarCertificadora: CertificadoraService,
    private subscriptionService: SubscriptionService,
    private UpCertificacao: UpCertificacaoService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.carregarCertificadoras();
  }

  private carregarCertificadoras() {
    this.selecionarCertificadora
      .ListasCertificadora(this.unidadeProdutiva.uf)
      .subscribe((result) => {
        this.certificadoras = result;
      });
  }

  ngOnDestroy() {
    // this.subscriptionService.unsubscribeAll();
  }

  salvar() {
    this.spinnerService.show();
    this.UpCertificacao.AlterarCertificadoraUp(
      this.unidadeProdutiva,
      this.certSelecionada
    ).subscribe(() => {
      this.activeModal.close();
      this.spinnerService.hide();
    });
  }
}
