import { AlertModalService } from './../../../services/alert-modal.service';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { ToastService } from './../../../services/toast.service';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { UnidadeProdutiva } from 'src/app/entities/unidade-produtiva';
import { NotaExplicativa, CheckListNotaExplicativa, ConfirmarNotaExplicativa } from './../../../entities/nota-explicativa';
import { NotaExplicativaService } from './../../../nota-explicativa.service';
import { Component, Input, OnInit } from '@angular/core';
import { ToastType } from 'src/app/entities/toast';






@Component({
  selector: 'app-modal-vdp-nota-explicativa',
  templateUrl: './modal-vdp-nota-explicativa.component.html',
  styleUrls: ['./modal-vdp-nota-explicativa.component.css']
})
export class ModalVdpNotaExplicativaComponent implements OnInit {


  constructor(private service: NotaExplicativaService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private alertService: AlertModalService
  ) {
    this.form = this.formBuilder.group({
      orders: new FormArray([])
    });
    this.addCheckboxes();
  }

  get ordersArray() {
    return this.form.controls.orders as FormArray;
  }

  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() modal: any;
  form: FormGroup;
  selectedSede: NotaExplicativa;
  checklist: [];
  certificacaoJaPreenchida: Boolean = true;
  fazendas: string[];
  @Output() notaEnviada = new EventEmitter();

  fazendasAssociadas: NotaExplicativa[];

  data: CheckListNotaExplicativa[] = [{
    descricao: '1. Contrato de Trabalho',
    id: 'contratoTrabalho',
    value: false
  },
  {
    descricao: '2. Proibição de Trabalho Infantil.',
    id: 'proibicaoTrabalhoInfantil',
    value: false
  },
  {
    descricao: '3. Proibição de Trabalho Análogo a Escravo - Condições Degradantes ou Indigna. ',
    id: 'proibicaoTrabalhoEscravo',
    value: false
  }, {
    descricao: '4. Liberdade de Associação Sindical.',
    id: 'liberdadeAssociacaoSindical',
    value: false
  },
  {
    descricao: '5. Proibição de Discriminação de Pessoas.',
    id: 'proibicaoDiscriminacaoPessoas',
    value: false
  },
  {
    descricao: '6. Segurança, Saúde Ocupacional e Meio Ambiente do Trabalho (NR31).',
    id: 'segurancaSaudeOcupacMeioAmbiTrabalho',
    value: false
  },
  {
    descricao: '7. Desempenho Ambiental.',
    id: 'desempenhoAmbiental',
    value: false
  },
  {
    descricao: '8. Boas Práticas Agrícolas.',
    id: 'boasPraticasAgricolas',
    value: false
  }];

  ngOnInit(): void {
    this.service.verificaNotaExplicativa(this.unidadeProdutiva)
      .subscribe(res => {
        this.exibirPreenchimentoDeNotaExplicativa();
      });
  }

  private exibirPreenchimentoDeNotaExplicativa() {
    this.service.getNotaExplicativa(this.unidadeProdutiva)
      .subscribe(res => {
        this.fazendasAssociadas = res;

        this.fazendas = this.fazendasAssociadas.map(a => a.descricao);
      });
  }

  private addCheckboxes() {
    this.data.forEach(() => this.ordersArray.push(new FormControl(false)));
  }

  selectHandler(event: any) {
    this.fazendasAssociadas.filter(f => f.id == event.target.value).findIndex(fa => {
      this.selectedSede = fa;
    })
  }

  submit() {
    const confirmarNota = this.geraConfirmarNotaExplicativa();

    this.alertService
      .showConfirm('Você confirma o registro de Nota Explicativa para esta Unidade Produtiva?',
        'Uma vez confirmado não será possível realizar alterações na Nota Explicativa.')
      .subscribe(confirm => {
        if (confirm) {
          this.service.confirmarNotaExplicativa(confirmarNota).subscribe(res => {
            this.toastService.showToast(
              res ? ToastType.SUCCESS : ToastType.DANGER,
              res ? "Sucesso" : "Falha", "Nota explicativa enviada");
          });
          this.notaEnviada.emit();
          this.modal.close();
        }
      });
  }

  private geraConfirmarNotaExplicativa() {
    const selecteds = this.form.value.orders;

    const confirmarNota = new ConfirmarNotaExplicativa();
    confirmarNota.upId = this.unidadeProdutiva.id;
    confirmarNota.upCertificacaoId = this.unidadeProdutiva.id;
    confirmarNota.upSedeId = this.selectedSede.id;
    confirmarNota.contratoTrabalho = selecteds[0];
    confirmarNota.proibicaoTrabalhoInfantil = selecteds[1];
    confirmarNota.proibicaoTrabalhoEscravo = selecteds[2];
    confirmarNota.liberdadeAssociacaoSindical = selecteds[3];
    confirmarNota.proibicaoDiscriminacaoPessoas = selecteds[4];
    confirmarNota.segurancaSaudeOcupacMeioAmbiTrabalho = selecteds[5];
    confirmarNota.desempenhoAmbiental = selecteds[6];
    confirmarNota.boasPraticasAgricolas = selecteds[7];

    return confirmarNota;
  }

  downloadNotaExplicativa() {

    this.service.previewNotaExplicativa(this.geraConfirmarNotaExplicativa()).subscribe((res: any) => {
      if (!res.conteudo) {
        this.toastService.showToast(ToastType.DANGER, "Falha", "Certificado não disponível");
        return;
      }

      const data = 'data:application/pdf;base64,' + res.conteudo;
      const link = document.createElement("a");
      const fileName = res.nomeArquivo;

      link.href = data;
      link.download = fileName;
      link.click();
    }
    );
  }


  close() {
    this.modal.close();
  }

  get liberarDownload(): boolean {
    return this.form.value.orders.some(select => select === true) && !!this.selectedSede;
  }
}
