import { DetalharUnidadeProdutiva } from './../../entities/unidade-produtiva';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { Services } from '../../services/service';
import { Safra } from '../../entities/safra';
import { UnidadeProdutiva } from '../../entities/unidade-produtiva';

@Component({
  selector: "modal-dados-up",
  templateUrl: './modal-dados-up.component.html',
})
export class ModalDadosUp implements OnInit {
  constructor(private modalService: NgbModal, private services: Services) { }

  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() safra: Safra;
  detalheUnidadeProdutiva: DetalharUnidadeProdutiva;

  ngOnInit(): void { }

  open(content) {
    if (
      this.unidadeProdutiva != null &&
      (this.safra === null ||
        this.safra === undefined ||
        (this.safra as any) === '')
    ) {
      this.services.getByIdSafra(
        'UnidadeProdutiva',
        this.unidadeProdutiva.id,
        0
      ).subscribe((dashboard) => {
        this.detalheUnidadeProdutiva = dashboard;
      });
    } else if (
      this.unidadeProdutiva != null &&
      !(
        this.safra === null ||
        this.safra === undefined ||
        (this.safra as any) === ''
      )
    ) {
      this.services.getByIdSafra(
        'UnidadeProdutiva',
        this.unidadeProdutiva.id,
        this.safra.id
      ).subscribe((dashboard) => {
        this.detalheUnidadeProdutiva = dashboard;
      });
    }
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  formatCep(cep) {
    return cep.slice(0, 2) + '.' + cep.slice(2, 5) + '-' + cep.slice(5, 8);
  }
}
