
export enum EtapasEnum {
    'Não convidada' = 1,
    'Convite enviado' = 2,
    'Convite recusado' = 3,
    'VDP' = 4,
    'VDP - Em andamento' = 5,
    'VDP - Concluída' = 6,
    'PCNC - Recusou prosseguir para a VCP' = 7,
    'PCNC' = 8,
    'PCNC - Em andamento' = 9,
    'PCNC - Ausente de Não Conformidades' = 10,
    'PCNC - Concluído' = 11,
    'VCP' = 12,
    'VCP - Em andamento' = 13,
    'VCP - Concluída' = 14,
    'VCP - Reprovada' = 15,
    'Certificado em emissão' = 16,
    'Certificada ABR / Não optante BCI' = 17,
    'Certificada ABR / Em andamento BCI' = 18,
    'Certificada ABR / Licenciada BCI' = 19,
    'Certificada ABR / Reprovada BCI' = 20,
    'Certificação Cancelada' = 21,
    'VCP - Aprovada' = 22,
    'PCNC - Optou por pular PCNC' = 23,
    'Certificada ABR / Cancelada BCI' = 24,
    'Convite Aceito' = 25,
    'Estadual fez o Upload do Termo' = 26,
    'Em revisão - Retorno de Etapa' = 27
}