<div *ngIf="!habilitarAcessarVdp" class="alert alert-info" role="alert">
  <fa-icon [icon]="iconInfo" size="sm"></fa-icon>&nbsp;Não compartilhe o link de acesso à lista VDP. Pessoas com acesso a este link poderão preencher o formulário indevidamente.
</div>
<div class="row">
  <div class="col-lg-3 mb-2 mb-lg-0">
    <titulo-modal>Formulário VDP</titulo-modal>
    <div class="row">
      <div class="col-6 col-lg-12">
        <button [disabled]="habilitarAcessarVdp" (click)="preencherVdp()" class="btn btn-sm btn-primary">
          <fa-icon [icon]="icon" size="sm"></fa-icon>&nbsp;Acessar VDP
        </button>
      </div>
      <div class="col-6 col-lg-12 mt-lg-2">
        <div class="row ml-0">
          <ativador-tooltip [isBtn]="true" label="Resumo da VDP (%)  " [tooltipEnabled]="false"
            tooltipText="O formulário ainda não foi enviado" (action)="open(content)">
          </ativador-tooltip>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-7">
    <app-status-convite [loading]="loadingStatus" title="Status" [status]="status"></app-status-convite>
  </div>
  <div class="col-lg-2">
    <dropdown-btn labelAtivadora="Outras opções">
      <dropdown-btn-item *ngIf="safraVigenteAtivaStatusVDPEmAndamentoCompleto" (actionPerformed)="reenviarEmail()">
        Reenviar e-mail VDP
      </dropdown-btn-item>
      <dropdown-btn-item *ngIf="podePularVdp" (actionPerformed)="pularEtapaVdp()">
        Pular etapa <strong>VDP e PCNC</strong>
      </dropdown-btn-item>
      <dropdown-btn-item (actionPerformed)="open(nota)" *ngIf="exibirNotaExplicativa">
        Acessar <strong>Nota Explicativa</strong>
      </dropdown-btn-item>
      <dropdown-btn-item *ngIf="podeSolicitarRetorno">
        <modal-retorno [etapa]="'VDP'" [etapaAtual] ="etapaAtual" [unidadeProdutiva]="unidadeProdutiva"></modal-retorno>
      </dropdown-btn-item>
    </dropdown-btn>
  </div>

</div>
<br>

<ng-template #content let-modal>
  <modal-etapa-vdp-nota [containerNota]="containerNota" [unidadeProdutiva]="unidadeProdutiva"></modal-etapa-vdp-nota>
</ng-template>

<ng-template #nota let-modal>
  <app-modal-vdp-nota-explicativa (notaEnviada)="loading()" [modal]="modal" *ngIf="!notaExplicativa"
    [unidadeProdutiva]="unidadeProdutiva">
  </app-modal-vdp-nota-explicativa>
  <app-modal-vdp-nota-explicativa-preenchida [modal]="modal" *ngIf="notaExplicativa"
    [unidadeProdutiva]="unidadeProdutiva">
  </app-modal-vdp-nota-explicativa-preenchida>
</ng-template>
