import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { Safra } from 'src/app/entities/safra';
import { SolicitacaoRetorno } from 'src/app/entities/solicitacao-retorno';
import { UnidadeProdutiva } from 'src/app/entities/unidade-produtiva';
import { AlertModalService } from 'src/app/services/alert-modal.service';
import { SolicitarRetornoEtapaService } from 'src/app/services/solicitar-retono-etapa.service';
import { StatusSolicitacaoDotColorEnum } from './status-solicitacao-dot-color-enum';
import { StatusSolicitacaoEnum } from './status-solicitacao-enum';

@Component({
    selector: 'modal-retorno',
    templateUrl: './modal-retorno.component.html',
    styleUrls: ['./modal-retorno.component.css']
})
export class ModalRetornoComponent implements OnInit {
    @Input() etapa: string;
    @Input() unidadeProdutiva: UnidadeProdutiva;
    safraEscolhida: Safra;
    solicitacao: SolicitacaoRetorno = null;
    @Input() etapaAtual: any;
    spinnerVisibility: boolean = false;
    habilitarSolicitacao: boolean = false;
    dotColor: string = '';

    constructor(
        private modalService: NgbModal,
        private solicitacaoServices: SolicitarRetornoEtapaService,
        private alertService: AlertModalService) { }

    ngOnInit(): void { }

    carregarSolicitacaoDeRetorno() {
        this.spinnerVisibility = true;
        this.solicitacaoServices.getByIdStatusAtual(this.etapaAtual.idStatusCertificacao).subscribe(res => {
            this.solicitacao = res

            if (this.solicitacao != null) {
                this.habilitarSolicitacao = true;

                this.dotColor = StatusSolicitacaoDotColorEnum[this.solicitacao.status];
                this.solicitacao.status = StatusSolicitacaoEnum[this.solicitacao.status];
            }

            this.spinnerVisibility = false;

        }, (err) => this.spinnerVisibility = false)
    }

    cancelarSolicitacao() {
        if (this.solicitacao == null) {
            return;
        }
        this.alertService.showConfirm(
                '',
                'Você tem certeza que deseja cancelar esta solicitação de retorno para a Unidade Produtiva selecionada?'
            ).pipe(
                map((confirmed) => {
                    if (confirmed) {
                        this.spinnerVisibility = true;
                        this.solicitacaoServices.cancelarSolicitacaoDeRetorno(this.solicitacao.id).subscribe(res => {
                            this.carregarSolicitacaoDeRetorno();
                        });
                    }
                })
            ).subscribe();
    }

    open(content) {
        this.modalService.open(content, { size: 'lg', centered: true });
        this.carregarSolicitacaoDeRetorno()
    }

}
