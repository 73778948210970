import { EtapaService } from "./../services/etapa.service";
import * as FileSaver from "file-saver";
import { merge, Observable, of } from "rxjs";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Component, Injectable, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { faSort } from "@fortawesome/free-solid-svg-icons";

import { SortDirection } from "../components/sortable-th/sortable-th.component";
import { Etapas } from "../entities/etapas";
import { SafraParaCertificacao } from "../entities/safrasCertificacao";
import { UnidadeProdutiva } from "../entities/unidade-produtiva";
import { AppService } from "../services/app-service";
import { PermissionService } from "../services/permission-service";
import { Services } from "../services/service";
import { SpinnerService } from "../services/spinner.service";
import { Base64ToFile } from "../utils/base64ToFile";

import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  startWith,
} from "rxjs/operators";
import { ModalHome } from "../entities/modal-home";
import { PermissoesService } from "../services/permissoes.service";
import { Setor } from "../entities/setor";
import { SetoresHome } from "../entities/setores-home";
import { PerfilUsuario } from "../entities/perfil";
import { NavigationEnd, Router, Scroll } from "@angular/router";
import { HeaderService } from "../services/header.service";
import { AuthService } from "../services/auth.service";
import { NgbTypeahead, NgbTypeaheadConfig } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { ToastService } from "../services/toast.service";
import { ToastType } from "../entities/toast";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  sortIcon = faSort;
  dashboard: UnidadeProdutiva[];
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  currentPageSize = 10;
  unidadeProdutiva = new UnidadeProdutiva();
  safraSelecionada = "";
  base64ToFile = new Base64ToFile();
  etapas: Etapas[] = [];
  nivelEtapa: Etapas[] = [];
  public spinnerVisibility: boolean = false;

  safrasCertificacao$: Observable<SafraParaCertificacao[]>;
  ufs = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MS",
    "MT",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];
  dataGrupoProdutor = [];
  dataMunicipio = [];

  searchText = new Subject();
  results: Observable<string[]>;

  sortField: string;
  sortDirection: SortDirection;

  profileForm = new FormGroup({
    id: new FormControl(""),
    unidadeProdutiva: new FormControl(""),
    uf: new FormControl(""),
    municipio: new FormControl(""),
    tipo: new FormControl(""),
    produtorGrupo: new FormControl(""),
    status: new FormControl(""),
    habilitada: new FormControl(""),
    bci: new FormControl(""),
    safraCertificacao: new FormControl(""),
    etapaAbr: new FormControl(""),
  });

  constructor(
    private Services: Services,
    private etapaServices: EtapaService,
    private appService: AppService,
    private permissionService: PermissionService,
    private spinnerService: SpinnerService,
    private permissoesService: PermissoesService,
    private router: Router,
    private headerService: HeaderService,
    private authService: AuthService,
    private toastService: ToastService
  ) { 
  }

  private carregarRedirecionamento() {
    this.router.events.subscribe((event: NavigationEnd) => {
      if (!this.authService.hasToken()) {
        return;
      }

      if (event.url === "/") {
        this.headerService.inicializar();
        this._carregarHome();
      }
    });
  }

  private _getSeachParams() {
    let params = new HttpParams();
    let usuarioLogado = JSON.parse(window.localStorage.getItem("usuario"));

    params = params.append("pageNumber", this.page.toString());
    params = params.append("pageSize", this.pageSize.toString());

    params = params.append("Id", this.profileForm.get("id").value.toString());
    params = params.append(
      "NomeUnidadeProdutiva",
      this.profileForm.get("unidadeProdutiva").value.toString()
    );
    params = params.append("Uf", this.profileForm.get("uf").value.toString());
    params = params.append(
      "Municipio",
      this.profileForm.get("municipio").value.toString()
    );
    params = params.append(
      "Tipo",
      this.profileForm.get("tipo").value.toString()
    );
    params = params.append(
      "ProdutorGrupo",
      this.profileForm.get("produtorGrupo").value.toString()
    );
    params = params.append(
      "Habilitada",
      this.profileForm.get("habilitada").value.toString()
    );
    params = params.append(
      "Status",
      this.profileForm.get("status").value.toString()
    );
    params = params.append(
      "ParticipanteBCI",
      this.profileForm.get("bci").value.toString()
    );
    params = params.append(
      "SafraCertificacao",
      this.profileForm.get("safraCertificacao").value.toString()
    );
    params = params.append(
      "EtapaAbrId",
      this.profileForm.get("etapaAbr").value.toString()
    );
    params = params.append("CodigoSafra", this.safraSelecionada);
    params = params.append("UsuarioIdLogado", usuarioLogado.UsuarioId);

    if (!!this.sortField && !!this.sortDirection) {
      params = params.append(
        "sortBy",
        `${this.sortField},${this.sortDirection}`
      );
    }

    return params;
  }

  refreshPage(): void {
    this.showSpinner();
    let params = this._getSeachParams();

    this.Services.getPagination<UnidadeProdutiva>("Dashboard", params)
      .subscribe(
        ({ data, pageNumber, pageSize, totalItems }) => {
          this.dashboard = data;
          this.page = pageNumber;
          this.pageSize = pageSize;
          this.collectionSize = totalItems;
          this.currentPageSize = this.dashboard.length;
        },
        (err) => {
          this.toastService.showToast(ToastType.DANGER, "Error", err);
        }
      )
      .add(() => {
        this.hideSpinner();
      });
  }

  private _exportPage(
    type: "status_ups" | "historico",
    format: "csv" | "excel"
  ): void {
    let params = this._getSeachParams();

    const route = `Dashboard/${
      format === "excel" ? "exportarExcel" : "exportarCsv"
    }?type=${type}&`;

    this.spinnerService.show();
    this.Services.getByWithParams(route, params)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe((response) => {
        FileSaver.saveAs(
          this.base64ToFile.b64toFile(
            response.fileContents,
            response.fileDownloadName,
            response.contentType
          ),
          response.fileDownloadName
        );
      });
  }

  ngOnInit() {
    this.carregarRedirecionamento();
    if (this.authService.hasToken()) {
      this.headerService.inicializar();
      this._carregarHome();
    }

    this.Services.getAll("Dashboard/ListaGrupoProdutor").subscribe((x) => {
      this.dataGrupoProdutor = x;
    });

    this.Services.getAll("Dashboard/ListaMunicipios").subscribe((x) => {
      this.dataMunicipio = x;
    });
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.dataGrupoProdutor.filter(
              (v) =>
                v
                  .toLocaleLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .indexOf(
                    term
                      .toLocaleLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  ) > -1
            )
      )
    );

  searchMunicipios = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.dataMunicipio
              .filter(
                (v) =>
                  v.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1
              )
              .splice(0, 10)
      )
    );

  private _carregarHome() {
    this.__carregarDashboard();
  }

  private __carregarDashboard() {
    this.appService.subscriber$.subscribe((safra) => {
      this.profileForm.get("safraCertificacao").setValue("");

      this.safraSelecionada = safra.toString();

      this._loadSafrasCertificacao();
      this.refreshPage();
    });
    this.buscarEtapas();
    this.buscarNivelEtapas();
  }

  private _loadSafrasCertificacao() {
    this.safrasCertificacao$ = this.Services.getBy(
      "Dashboard/listaSafrasParaCertificacao",
      this.safraSelecionada
    );
  }

  showSpinner() {
    this.spinnerService.show();
  }

  hideSpinner() {
    this.spinnerService.hide();
  }

  onSubmit() {
    this.refreshPage();
  }

  exportarStatusUpsExcel() {
    this._exportPage("status_ups", "excel");
  }

  exportarStatusUpsCsv() {
    this._exportPage("status_ups", "csv");
  }

  exportarHistoricoCsv() {
    this._exportPage("historico", "csv");
  }

  exportarHistoricoExcel() {
    this._exportPage("historico", "excel");
  }

  buscarEtapas() {
    this.etapaServices.getEtapas().subscribe((x) => {
      this.etapas = x;
    });
  }

  buscarNivelEtapas() {
    this.etapaServices.getNivelEtapas().subscribe((x) => {
      this.nivelEtapa = x;
    });
  }

  setor(setor: string): SetoresHome {
    if (!SetoresHome[setor]) {
      throw new Error("Setor da home não existe");
    }
    return SetoresHome[setor];
  }

  podeVerSetorPorPerfil(perfil: PerfilUsuario, setor: SetoresHome): boolean {
    const setoresPermitidos: SetoresHome[] =
      this.permissoesService.getPermissoes(perfil, Setor.HOME) as SetoresHome[];

    return setoresPermitidos.some((setorPermitido) => setorPermitido === setor);
  }

  permissaoExportacao() {
    return this.permissionService
      .getPerfisValidos()
      ?.some((perfil) => perfil !== PerfilUsuario.BCI);
  }

  podeVerSetor(setor: SetoresHome) {
    return this.permissionService
      .getPerfisValidos()
      ?.some((perfil) => this.podeVerSetorPorPerfil(perfil, setor));
  }

  modal(modal: string): ModalHome {
    if (!ModalHome[modal]) {
      throw new Error("Modal não existe");
    }
    return ModalHome[modal];
  }

  podeVerModal(modalHome: ModalHome) {
    return this.permissionService.getPerfisValidos().some((perfil) => {
      return (
        modalHome ===
        (this.permissoesService.getPermissoes(
          perfil,
          Setor.MODAL_HOME
        ) as ModalHome)
      );
    });
  }

  reset() {
    this.profileForm = new FormGroup({
      id: new FormControl(""),
      unidadeProdutiva: new FormControl(""),
      uf: new FormControl(""),
      municipio: new FormControl(""),
      tipo: new FormControl(""),
      produtorGrupo: new FormControl(""),
      status: new FormControl(""),
      habilitada: new FormControl(""),
      bci: new FormControl(""),
      safraCertificacao: new FormControl(""),
      etapaAbr: new FormControl(""),
    });
  }

  handleChangeSort(field: string, direction: SortDirection) {
    this.sortField = field;
    this.sortDirection = direction;

    this.refreshPage();
  }

  abrirMeusFormularios() {
    console.log("abrir meus formularios");
  }

  buscarUnidadesProdutivas() {
    this.refreshPage();
  }
}
