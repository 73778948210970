import { PaginatedApiResponse } from "./../entities/api-response";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { APP_API, APP_API_CONVITE, APP_CONVITES } from "../app.api";
import { Formulario } from "../entities/formulario";

@Injectable()
export class Services {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
      "Access-Control-Allow-Headers": "Content-Type",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }),
  };

  constructor(private http: HttpClient) {}

  getAll(controller): Observable<any[]> {
    return this.http
      .get<any[]>(`${APP_API}/${controller}`)
      .pipe(map((res) => res));
  }

  getFormulario(controller): Observable<Formulario> {
    return this.http
      .get<Formulario>(`${APP_API}/${controller}`)
      .pipe(map((res) => res));
  }

  get<T = any>(controller): Observable<T> {
    return this.http.get<T>(`${APP_API}/${controller}`).pipe(map((res) => res));
  }

  getBy<T = any>(controller: string, param: string | number): Observable<T> {
    return this.http.get<T>(
      `${APP_API}/${controller}/${param}`,
      this.httpOptions
    );
  }

  getDocument(controller, params): Observable<any> {
    return this.http
      .get<any>(`${APP_API}/${controller}`, { params: params })
      .pipe(map((resp) => resp));
  }

  getByWithParams(controller, params): Observable<any> {
    return this.http
      .get<any>(`${APP_API}/${controller}`, { params: params })
      .pipe(map((resp) => resp));
  }

  getById(controller, id): Observable<any> {
    return this.http
      .get<any>(`${APP_API}/${controller}/${id}`)
      .pipe(map((resp) => resp));
  }
  getByIdSafra(controller, id, safra): Observable<any> {
    return this.http
      .get<any>(`${APP_API}/${controller}/${id}/${safra}`)
      .pipe(map((resp) => resp));
  }

  getPagination<T = any, G = any>(
    controller,
    params
  ): Observable<PaginatedApiResponse<T, G>> {
    return this.http.get<PaginatedApiResponse<T, G>>(
      `${APP_API}/${controller}`,
      {
        params: params,
      }
    );
  }

  patch(controller, data: any = null): Observable<any> {
    var result = this.http
      .patch<any>(`${APP_API}/${controller}`, data, this.httpOptions)
      .pipe(
        // tap((res) => console.log(res)),
        map((res) => (res ? res : of(["teste"])))
      );

    return result;
  }
  patchWithParams(controller, params): Observable<any> {
    return this.http
      .patch<any>(`${APP_API}/${controller}`, null, { params: params })
      .pipe(map((resp) => resp));
  }

  post(controller, data: any): Observable<any> {
    return this.http
      .post<any>(`${APP_API}/${controller}`, data, this.httpOptions)
      .pipe(map((resp) => resp));
  }
  postWithParams(controller, params): Observable<any> {
    return this.http
      .post<any>(`${APP_API}/${controller}`, null, { params: params })
      .pipe(map((resp) => resp));
  }
  put(controller, data, id): Observable<any> {
    return this.http
      .put<any>(`${APP_API}/${controller}/${id}`, data, this.httpOptions)
      .pipe(map((resp) => resp));
  }

  delete(controller, id): Observable<any> {
    return this.http
      .delete<any>(`${APP_API}/${controller}/${id}`)
      .pipe(map((resp) => resp));
  }

  cancelarCertificacao(
    controller,
    unidadeProdutivaId,
    safra,
    motivo
  ): Observable<any> {
    return this.http
      .post<any>(
        `${APP_API}/${controller}?idUnidadeProdutiva=${unidadeProdutivaId}&idSafra=${safra}&motivo=${motivo}`,
        this.httpOptions
      )
      .pipe(map((resp) => resp));
  }

  getConvites<T = any>(controller): Observable<T> {
    return this.http
      .get<T>(`${APP_API_CONVITE}/${controller}`)
      .pipe(map((res) => res));
  }

  redirectConvites() {
    window.open(APP_CONVITES, "_blank");
  }
}
