<a (click)="open(content)" class="col-lg-1">
  Histórico ABR
</a>
<ng-template #content let-modal>
  <div class="container">
    <div class="col-lg-12" align="center">
      <br>
      <titulo-modal>Histórico ABR desta Unidade Produtiva</titulo-modal>
    </div>
    <div class="col-lg-12">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
            style="padding-left:33px" aria-haspopup="true" aria-expanded="false">
            Safra {{safraEscolhida?.descricaoSafra}}
          </a>

          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <div *ngFor="let safra of listaSafras">
              <a class="dropdown-item" style="cursor:pointer"
                (click)="buscarHistoricoCertificacao(safra)">{{safra.descricaoSafra}}</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="row" style="padding:50px">
      <div class="col-md-10">
        <ul class="cbp_tmtimeline">
          <li *ngFor="let historicoAbr of historicoCertificacao">
            <div *ngIf="historicoAbr.id !== 1; else elseBlock">
              <time class="cbp_tmtime" datetime="historicoAbr.dataInclusao">{{historicoAbr.data | date:"dd/MM/yyyy
                HH:mm"}}</time>
              <div class="cbp_tmicon-{{historicoAbr.id}}"><i class="zmdi zmdi-label"></i></div>
              <div class="cbp_tmlabel">
                <span *ngIf="historicoAbr.id === 18 || historicoAbr.id === 19 || historicoAbr.id === 17; else elseBlockDescricao">
                  <b *ngIf="historicoAbr.id === 18 || historicoAbr.id === 17">{{historicoAbr.descricao}} por {{historicoAbr.usuarioCpf}} : {{historicoAbr.numeroCertificado}} </b>
                  <b *ngIf="historicoAbr.id === 19">{{historicoAbr.descricao}} por {{historicoAbr.usuarioCpf}} : {{historicoAbr.numeroCertificadoBCI}} </b>
                </span>
                <ng-template #elseBlockDescricao>
                  {{historicoAbr.descricao}} por {{historicoAbr.usuarioCpf}}
                </ng-template>
              </div>
            </div>
            <ng-template #elseBlock>
              <time class="cbp_tmtime" datetime="historicoAbr.dataInclusao">--</time>
              <div class="cbp_tmicon-1"><i class="zmdi zmdi-label"></i></div>
              <div class="cbp_tmlabel">
                <span>{{historicoAbr.descricao}}</span>
              </div>
            </ng-template>
          </li>
        </ul>
        <div *ngIf="historicoCertificacao?.length == 0" style="text-align: center;">
          Não há histórico para esta Unidade Produtiva no período selecionado.
        </div>
      </div>
    </div>
  </div>
</ng-template>
