import { Component, OnInit } from "@angular/core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-external-link",
  templateUrl: "./external-link.component.html",
  styleUrls: ["./external-link.component.css"],
})
export class ExternalLinkComponent implements OnInit {
  icon = faExternalLinkAlt;

  constructor() {}

  ngOnInit(): void {}
}
