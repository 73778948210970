<div>
  <h4>Programa ABR</h4>
</div>
<form *ngIf="!spinnerVisibility" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
  <div class="form-row">
    <div *ngIf="setor('FORM_ID') | podeVerSetorPipe" class="form-group col-md-1">
      <label for="id" class="col-form-label col-form-label-sm"><span data-toggle="tooltip" data-placement="top"
          title="Identificador da Unidade Produtiva proveniente do SINDA">ID</span></label>
      <ng-template #upIdTootip>Identificador da Unidade Produtiva proveniente do SINDA</ng-template>
      <input type=" text" [ngbTooltip]="upIdTootip" class="form-control form-control-sm" id="id" name="id"
        formControlName="id" />
    </div>
    <div *ngIf="setor('FORM_UNIDADE_PRODUTIVA' )| podeVerSetorPipe" class="form-group col-md-2">
      <label for="unidadeProdutiva" class="col-form-label col-form-label-sm">Unidade Produtiva</label>
      <ng-template #upNomeTootip>Digite parte do nome ou nome completo da Unidade Produtiva</ng-template>
      <input type="text" [ngbTooltip]="upNomeTootip" class="form-control form-control-sm" id="unidadeProdutiva"
        name="unidadeProdutiva" formControlName="unidadeProdutiva" />
    </div>
    <div *ngIf="setor('FORM_UF') | podeVerSetorPipe" class="form-group col-md-1">
      <label for="uf" class="col-form-label col-form-label-sm">UF</label>
      <select type="text" class="form-control form-control-sm" id="uf" name="uf" formControlName="uf">
        <option selected value="">Selecione...</option>
        <option *ngFor="let uf of ufs " [value]="uf">{{uf}}</option>
      </select>
    </div>
    <div *ngIf="setor('FORM_MUNICIPIO') | podeVerSetorPipe" class="form-group col-md-2">
      <label for="municipio" class="col-form-label col-form-label-sm">Município</label>
      <ng-template #produtorMunicipioTooltip>Digite parte do nome ou nome completo do Município.</ng-template>
      <input type="text" class="form-control form-control-sm" id="municipio" name="municipio"
        formControlName="municipio" [ngbTooltip]="produtorMunicipioTooltip" [ngbTypeahead]="searchMunicipios" />
    </div>
    <div *ngIf="setor('FORM_TIPO') | podeVerSetorPipe" class="form-group col-md-2">
      <label for="tipo" class="col-form-label col-form-label-sm">Tipo</label>
      <select id="tipo" class="form-control form-control-sm" name="tipo" formControlName="tipo">
        <option selected value="">Selecione...</option>
        <option>Grupo</option>
        <option>Produtor</option>
      </select>
    </div>
    <div *ngIf="setor('FORM_PRODUTOR_GRUPO') | podeVerSetorPipe" class="form-group col-md-2">
      <label for="produtorGrupo" class="col-form-label col-form-label-sm">Produtor/Grupo</label>
      <ng-template #produtorGrupoTooltip>Digite parte do nome ou nome completo do Produtor ou Grupo de
        Produtores.</ng-template>
      <input type="text" class="form-control form-control-sm" id="produtorGrupo" name="produtorGrupo"
        formControlName="produtorGrupo" [ngbTooltip]="produtorGrupoTooltip" [ngbTypeahead]="search" />
    </div>
    <div *ngIf="setor('FORM_STATUS') | podeVerSetorPipe" class="form-group col-md-2">
      <label for="status" class="col-form-label col-form-label-sm">Status</label>
      <ng-template #statusTooltip><b>Ativo =</b> Unidades Produtivas operando e plantando na Safra <br />
        <b>Inativo =</b> Unidades Produtivas inoperantes e sem plantio na Safra</ng-template>
      <select id="status" class="form-control form-control-sm" name="status" formControlName="status"
        [ngbTooltip]="statusTooltip">
        <option selected value="">Selecione...</option>
        <option value="Ativo">Ativo</option>
        <option value="Inativo">Inativo</option>
      </select>
    </div>
  </div>
  <div class="form-row">
    <div *ngIf="setor('FORM_HABILITADO') | podeVerSetorPipe" class="form-group col-md-2">
      <label for="habilitada" class="col-form-label col-form-label-sm">Habilitada</label>
      <ng-template #habilitadaTooltip>Unidades Produtivas liberadas para participar do Programa ABR na
        safra</ng-template>
      <select id="habilitada" [ngbTooltip]="habilitadaTooltip" class="form-control form-control-sm" name="habilitada"
        formControlName="habilitada">
        <option selected value="">Selecione...</option>
        <option value="1">Sim</option>
        <option value="0">Não</option>
      </select>
    </div>
    <div *ngIf="setor('FORM_PARTICIPANTE_BCI') | podeVerSetorPipe" class="form-group col-md-2">
      <label for="participanteBci" class="col-form-label col-form-label-sm">Status BCI</label>
      <select id="participanteBci" class="form-control form-control-sm" name="participanteBci" formControlName="bci">
        <option selected value=""> Selecione...</option>
        <option value="2">Convidada</option>
        <option value="3">Convite Aceito</option>
        <option value="4">Convite Recusado</option>
        <option value="1">Não Convidada</option>
      </select>
    </div>
    <div *ngIf="setor('FORM_SAFRA_CERTIFICACAO') | podeVerSetorPipe" class="form-group col-md-2">
      <label for="safraCertificacao" class="col-form-label col-form-label-sm">Safra de Certificação</label>
      <select id="safraCertificacao" class="form-control form-control-sm" name="safraCertificacao"
        formControlName="safraCertificacao">
        <option selected value="">Selecione...</option>
        <option *ngFor="let safra of safrasCertificacao$ | async" [value]="safra.codigoSafra">{{safra.descricao}}
        </option>
      </select>
    </div>
    <div *ngIf="setor('FORM_ETAPA_ABR') | podeVerSetorPipe" class="form-group col-md-2">
      <label for="etapaAbr" class="col-form-label col-form-label-sm">Etapa ABR</label>
      <select id="etapaAbr" class="form-control form-control-sm" name="etapaAbr" formControlName="etapaAbr">
        <option selected value="">Selecione...</option>
        <ng-container *ngFor="let etapa of etapas">
          <option value="{{ etapa.id }}">{{ etapa.descricao }}</option>
          <ng-container *ngFor="let nivel of nivelEtapa">
            <option *ngIf="nivel.nivelEtapaId === etapa.id" value="{{ nivel.id }}">&nbsp; &nbsp;{{ nivel.descricao }}
            </option>
          </ng-container>
        </ng-container>
      </select>
    </div>
    <div class="d-flex align-items-end form-group offset-1">
      <button type="submit" class="btn btn-primary btn-sm">Pesquisar</button>&nbsp;
      <button class="btn btn-outline-secondary btn-sm" (click)="reset()">Limpar</button>
    </div>
  </div>
</form>

<div *ngIf="!spinnerVisibility" class="table">
  <div class="table-options row justify-content-end mr-0 ml-0" [ngClass]="{ 'justify-content-between':
        (setor('BTN_DATA_LIMITE') | podeVerSetorPipe) ||
        (setor('BTN_FORMULARIOS') | podeVerSetorPipe)
      }">
    <div *ngIf="setor('BTN_DATA_LIMITE') | podeVerSetorPipe" class="btn-group">
      <modal-data-limite-bci></modal-data-limite-bci>
    </div>
    <!-- <div *ngIf="setor('BTN_FORMULARIOS') | podeVerSetorPipe" class="d-flex align-items-center">
      <ativador-tooltip
        [isBtn]="true"
        label="Documentos ABR (Offline)"
        [tooltipEnabled]="true"
        tooltipText="Visualize os formularios VDP, PCNC e Notas explicativas e tenha a possibilidade de preencher offline."
        (action)="abrirMeusFormularios()"
      ></ativador-tooltip>
      <div class="ml-2">
        <info-tooltip text="Visualize os formularios VDP, PCNC e Notas explicativas e tenha a possibilidade de preencher offline."></info-tooltip>
      </div>
    </div> -->
    <dropdown-btn labelAtivadora="Exportação de dados">
      <dropdown-btn-item (actionPerformed)="exportarStatusUpsCsv()">Histórico das UPs (.csv)</dropdown-btn-item>
      <dropdown-btn-item (actionPerformed)="exportarStatusUpsExcel()" [marginBottom]="true">Histórico das UPs (.xlsx)
      </dropdown-btn-item>
      <dropdown-btn-item *ngIf=permissaoExportacao() (actionPerformed)="exportarHistoricoCsv()">Respostas dos
        Formulários (.csv)</dropdown-btn-item>
      <dropdown-btn-item *ngIf=permissaoExportacao() (actionPerformed)="exportarHistoricoExcel()">Respostas dos
        Formulários (.xlsx)</dropdown-btn-item>
    </dropdown-btn>
  </div>
  <div class="table-responsive table-striped">
    <table class="table" class="nowrap-table">
      <thead>
        <tr>
          <th *ngIf="setor('COLUNA_ID') | podeVerSetorPipe"
            [ngClass]="{'sticky-column':setor('COLUNA_ID') | podeVerSetorPipe}">
            <app-sortable-th [direction]="sortField === 'Id' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('Id', $event)">ID UP</app-sortable-th>
          </th>
          <th>
            <app-sortable-th [direction]="sortField === 'IdProdutorGrupo' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('IdProdutorGrupo', $event)">ID Produtor/Grupo</app-sortable-th>
          </th>
          <th *ngIf="setor('COLUNA_TIPO') | podeVerSetorPipe" scope="col">
            <app-sortable-th [direction]="sortField === 'Tipo' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('Tipo', $event)">Tipo</app-sortable-th>
          </th>
          <th *ngIf="setor('COLUNA_PRODUTOR_GRUPO') | podeVerSetorPipe">
            <app-sortable-th [direction]="sortField === 'ProdutorGrupo' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('ProdutorGrupo', $event)">Produtor/Grupo</app-sortable-th>
          </th>
          <th *ngIf="setor('COLUNA_UNIDADE_PRODUTIVA') | podeVerSetorPipe" [ngClass]="
          {
           'sticky-column-up':(setor('COLUNA_ID') | podeVerSetorPipe ) === true,
           'sticky-column':(setor('COLUNA_ID') | podeVerSetorPipe) === false
          }">
            <app-sortable-th [direction]="sortField === 'NomeUnidadeProdutiva' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('NomeUnidadeProdutiva', $event)">Unidade Produtiva
            </app-sortable-th>
          </th>
          <th *ngIf="setor('COLUNA_STATUS') | podeVerSetorPipe">
            <app-sortable-th [direction]="sortField === 'Status' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('Status', $event)">Status</app-sortable-th>
          </th>
          <th *ngIf="setor('COLUNA_HABILITADO') | podeVerSetorPipe">
            <app-sortable-th [direction]="sortField === 'Habilitada' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('Habilitada', $event)">Habilitada</app-sortable-th>
          </th>
          <th *ngIf="setor('COLUNA_UF') | podeVerSetorPipe" scope="col">UF</th>
          <th *ngIf="setor('COLUNA_MUNICIPIO') | podeVerSetorPipe" scope="col">
            <app-sortable-th [direction]="sortField === 'Municipio' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('Municipio', $event)">Município</app-sortable-th>
          </th>
          <th *ngIf="setor('COLUNA_PARTICIPANTE_BCI') | podeVerSetorPipe">
            <app-sortable-th [direction]="sortField === 'ParticipanteBCI' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('ParticipanteBCI', $event)">Status BCI</app-sortable-th>
          </th>
          <th *ngIf="setor('COLUNA_SAFRA_CERTIFICACAO') | podeVerSetorPipe">
            <app-sortable-th [direction]="sortField === 'CodigoSafra' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('CodigoSafra', $event)">Safra de Certificação</app-sortable-th>
          </th>
          <th>
            <app-sortable-th [direction]="sortField === 'Status_Convites' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('Status_Convites', $event)">Status Convites</app-sortable-th>
          </th>
          <th *ngIf="setor('COLUNA_ETAPA_ABR') | podeVerSetorPipe" style="min-width: 152px;">
            <app-sortable-th [direction]="sortField === 'EtapaABR' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('EtapaABR', $event)">Status ABR</app-sortable-th>
          </th>
          <th>
            Reponsável(eis) ABR
          </th>
          <th *ngIf="setor('COLUNA_DATA_ULTIMA_ATUALIZACAO') | podeVerSetorPipe"
            style="white-space: normal; min-width: 150px;">
            <app-sortable-th [direction]="sortField === 'DataUltimaAtualizacao' ? sortDirection : null"
              (sortDirectionChanged)="handleChangeSort('DataUltimaAtualizacao', $event)">Data da última atualização
            </app-sortable-th>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let m of dashboard">
          <td *ngIf="setor('COLUNA_ID') | podeVerSetorPipe"
            [ngClass]="{'sticky-column':setor('COLUNA_ID') | podeVerSetorPipe}"><strong>{{ m.id }}</strong></td>
          <td>{{m.idProdutorGrupo}} </td>
          <td *ngIf="setor('COLUNA_TIPO') | podeVerSetorPipe">{{ m.tipo | uppercase }}</td>
          <td *ngIf="setor('COLUNA_PRODUTOR_GRUPO') | podeVerSetorPipe">{{ m.produtorGrupo | uppercase }}</td>
          <td *ngIf="setor('COLUNA_UNIDADE_PRODUTIVA') | podeVerSetorPipe" [ngClass]="
          {
           'sticky-column-up':(setor('COLUNA_ID') | podeVerSetorPipe) === true,
           'sticky-column':(setor('COLUNA_ID') | podeVerSetorPipe) === false
          }">{{ m.nomeUnidadeProdutiva | uppercase }}</td>
          <td *ngIf="setor('COLUNA_STATUS') | podeVerSetorPipe" data-html="true" title="Ativo = Unidades Produtivas operando e plantando na Safra
          Inativo = Unidades Produtivas inoperantes e sem plantio na Safra">
            {{ m.status | uppercase }}</td>
          <td *ngIf="setor('COLUNA_HABILITADO') | podeVerSetorPipe">{{ m.habilitada | yesNoEmpty }}</td>
          <td *ngIf="setor('COLUNA_UF') | podeVerSetorPipe">{{ m.uf }}</td>
          <td *ngIf="setor('COLUNA_MUNICIPIO') | podeVerSetorPipe">{{ m.municipio | uppercase }}</td>
          <td *ngIf="setor('COLUNA_PARTICIPANTE_BCI') | podeVerSetorPipe">{{ m.participanteBCI |uppercase}}</td>
          <td *ngIf="setor('COLUNA_SAFRA_CERTIFICACAO') | podeVerSetorPipe">{{m.safraCertificacao === 0 ? '' :
            m.safraCertificacao + 'ª'}}</td>
          <td>
            <app-etapa-abr-dashboard-td [etapa]="m.statusConvites"></app-etapa-abr-dashboard-td>
          </td>
          <td *ngIf="setor('COLUNA_ETAPA_ABR') | podeVerSetorPipe" style="white-space: normal;">
            <app-etapa-abr-dashboard-td [etapa]="m.etapaAbr"></app-etapa-abr-dashboard-td>
          </td>
          <td>
            <div *ngFor="let r of m.responsaveis">
              {{r}}
              <br>
            </div>
          </td>

          <td *ngIf="setor('COLUNA_DATA_ULTIMA_ATUALIZACAO') | podeVerSetorPipe">{{m.dataUltimaEtapa |
            date:'dd/MM/yyyy'}}</td>

          <td *ngIf="modal('ESTADUAL') | podeVerSetorModalPipe">
            <modal-convite-estadual [unidadeProdutiva]="m" [safraId]="safraSelecionada"
              (etapaTrocada)="buscarUnidadesProdutivas()"></modal-convite-estadual>
          </td>

          <td *ngIf="modal('ABRAPA') | podeVerSetorModalPipe">
            <modal-gestao-abrapa [unidadeProdutiva]="m" (etapaTrocada)="buscarUnidadesProdutivas()">
            </modal-gestao-abrapa>
          </td>

          <td *ngIf="modal('CERTIFICADORA') | podeVerSetorModalPipe">
            <modal-certificadora [unidadeProdutiva]="m" (etapaTrocada)="buscarUnidadesProdutivas()">
            </modal-certificadora>
          </td>

          <td *ngIf="modal('BCI') | podeVerSetorModalPipe">
            <modal-licenca-bci [unidadeProdutiva]="m" (etapaTrocada)="buscarUnidadesProdutivas()"></modal-licenca-bci>
          </td>

          <td *ngIf="modal('RESPONSAVEL_ABR') | podeVerSetorModalPipe">
            <app-modal-responsavel-abr [unidadeProdutiva]="m" (etapaTrocada)="buscarUnidadesProdutivas()">
            </app-modal-responsavel-abr>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="d-flex flex-column flex-lg-row justify-content-between p-2">
    <p class="text-center">
      Exibindo {{ currentPageSize }} registros de {{ collectionSize }}
    </p>
    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [maxSize]="5" [rotate]="true"
      [pageSize]="pageSize" (pageChange)="refreshPage()" class="d-flex d-lg-block justify-content-center">
    </ngb-pagination>
    <div class="d-flex justify-content-center">
      <select class="custom-select" style="width: auto;" [(ngModel)]="pageSize" (ngModelChange)="refreshPage()">
        <option [ngValue]="10">10 itens por página</option>
        <option [ngValue]="25">25 itens por página</option>
        <option [ngValue]="50">50 itens por página</option>
        <option [ngValue]="100">100 itens por página</option>
        <option [ngValue]="200">200 itens por página</option>
      </select>
    </div>
  </div>
</div>