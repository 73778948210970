import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dropdown-btn',
  templateUrl: './dropdown-btn.component.html',
  styleUrls: ['./dropdown-btn.component.css']
})
export class DropdownBtnComponent implements OnInit {

  @Input() labelAtivadora: string;

  constructor() { }

  ngOnInit(): void {

  }

}
