import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-label-value",
  templateUrl: "./label-value.component.html",
  styleUrls: ["./label-value.component.css"],
})
export class LabelValueComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() loading: boolean;
  @Input() hide = false;

  constructor() {}

  ngOnInit(): void {}
}
