<a href="#" class="btn btn-primary btn-sm btn-block" role="button" aria-pressed="true" (click)="open(content)">
    Gerenciar solicitações de retorno
</a>

<br>

<ng-template #content let-modal>
    <div class="modal-body">
        <div class="col-lg-12">
            <titulo-modal> Solicitações de Retorno</titulo-modal>
        </div>
        <br>
        <table class="table nowrap-table">
            <thead>
                <tr>
                    <th>
                        Solicitante    
                    </th>
                    <th>
                        Data/Hora
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of listaSolicitacoes">
                    <td>{{ item.solicitante | uppercase }}</td>
                    <td>{{ item.dataSolicitacao | date:'dd/MM/yyyy HH:mm:ss' }}</td>
                    <td>
                        <span [ngClass]="buscarCorEtapa(item.status)"></span>
                        <b>
                            {{item.status.toLowerCase() === 'em_analise' ? 'EM ANÁLISE' : item.status | uppercase }}
                        </b>
                    </td>
                    <td>
                        <app-gerenciar-solicitacao-retorno [dadosSolicitacao]="item" (update)="_buscarEtapas()">
                        </app-gerenciar-solicitacao-retorno>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-sm" (click)="modal.close()">Cancelar</button>
    </div>
</ng-template>