import { ConviteService } from './../../services/convite.service';
import { ToastService } from './../../services/toast.service';
import { VdpService } from 'src/app/services/vdp.service';
import * as FileSaver from "file-saver";
import { Observable } from "rxjs";
import { finalize, timeout } from "rxjs/operators";
import {
  AndamentoCertificacao,
  SetoresAndamentoCertificacao,
} from "src/app/entities/andamento-certificacao";
import { Status, StatusPcnc } from "src/app/entities/status";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";

import { Component, Input } from "@angular/core";

import { EtapaABR } from "../../entities/etapas";
import { PerfilUsuario } from "../../entities/perfil";
import { AndamentoCertificacaoService } from "../../services/andamento-certificacao.service";
import { BciService } from "../../services/bci.service";
import { CertificadoAbrService } from "../../services/certificado-abr.service";
import { PermissionService } from "../../services/permission-service";
import { Services } from "../../services/service";
import { SpinnerService } from "../../services/spinner.service";
import { TipoFormulario } from 'src/app/entities/formulario';
import { CriadorFormularioService } from 'src/app/services/criador-formulario.service';
import { ToastType } from 'src/app/entities/toast';
import { VcpService } from 'src/app/services/vcp.service';

@Component({
  selector: "modal-andamento-certificacao",
  templateUrl: "./modal-andamento-certificacao.component.html",
})
export class ModalAndamentoCertificao {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  status: Status;
  listStatus: any[];

  statusConvite: Status[];
  statusConviteAbr: Status;

  statusFinalizacaoAbr$: Observable<Status>;
  statusLicencaBci$: Observable<Status>;

  podeAcessarTermoAdesaoConvite = false;
  podeAcessarVdp = false;
  podeAcessarPcnc = false;
  podeAcessarVcp = false;
  podeAcessarCertificadoAbr = false;
  podeEfeturaDownload = false;
  podeAcessarDownloadBci = false;
  podeAcessarTermoAdesao = false;

  andamentoFinalizacaoAbr: AndamentoCertificacao;
  andamentoLicencaBci: AndamentoCertificacao;

  statusVdp: Status;
  statusVcp: Status;
  statusPcnc: StatusPcnc;
  statusFinalizacaoAbr: Status;
  statusLicencaBci: Status;
  ultimoStatus: Status;
  usuarioLogado: any;

  loadingStatusConvite = false;
  loadingStatusVdp = false;
  loadingStatusPcnc = false;
  loadingStatusVcp = false;
  loadingStatusAbr = false;
  loadingStatusBci = false;

  constructor(
    private andamentoCertificacaoService: AndamentoCertificacaoService,
    private service: Services,
    private spinnerService: SpinnerService,
    private certificadoAbrService: CertificadoAbrService,
    private bciService: BciService,
    private permissionService: PermissionService,
    private vdpService: VdpService,
    private criadorFormularioService: CriadorFormularioService,
    private toastService: ToastService,
    private conviteService: ConviteService,
    private vcpService: VcpService
  ) { }

  ngOnInit(): void {
    this.usuarioLogado = JSON.parse(window.localStorage.getItem("usuario"));

    this._carregarStatusConvites();
    this._carregarStatusVdp();
    this._carregarStatusVcp();
    this._carregarStatusPcnc();
    this._carregarStatusAbr();
    this._carregarStatusBci();
    // this._verificarCertificadoAbr()
    this._podeAcessarTermoAdesao();
  }

  private _carregarStatusConvites() {
    if (this.podeVerSetor(SetoresAndamentoCertificacao.CONVITES)) {
      this.loadingStatusConvite = true;
      this.andamentoCertificacaoService
        .getStatusConvite(this.unidadeProdutiva)
        .subscribe((status) => {
          this.statusConviteAbr = status.abr;
          this.statusConvite = [
            status.abr, status.bci
          ];
          this.podeAcessarTermoAdesaoConvite =
            status.abr.etapa === EtapaABR.CONVITE_ACEITO_ABR ||
            status.abr.etapa === EtapaABR.CONVITE_RECUSADO;
          this.podeAcessarTermoAdesaoConvite = true;
        },
          error => {
            console.log("error", error);
          }
        ).add(() => {this.loadingStatusConvite = false;});
    }
  }

  private _carregarStatusVdp() {
    return new Promise((success, reject) => {
      if (this.podeVerSetor(SetoresAndamentoCertificacao.VDP)) {
        this.loadingStatusVdp = true;
        this.andamentoCertificacaoService
          .getStatusVdp(this.unidadeProdutiva)
          .pipe(
            finalize(() => {
              this.loadingStatusVdp = false;
            })
          )
          .subscribe((status) => {
            this.statusVdp = status;
            const naoPodeAcessarVdp =
              status.etapa === EtapaABR.NAO_INICIADO || (status.etapa !== EtapaABR.VDP_CONCLUIDA && this.temPerfil('PRODUTOR')) || status.etapa === EtapaABR.VDP_OPTOU_PULAR_VDP;
            this.podeAcessarVdp = !naoPodeAcessarVdp;

            success(status);
          });
      }
    })
    
  }

  private _carregarStatusVcp() {
    if (this.podeVerSetor(SetoresAndamentoCertificacao.VCP)) {
      this.loadingStatusVcp = true;
      this.andamentoCertificacaoService
        .getStatusVcp(this.unidadeProdutiva)
        .pipe(
          finalize(() => {
            this.loadingStatusVcp = false;
          })
        )
        .subscribe((status) => {
          this.statusVcp = status;
          this._permitirAcessarVcp(status);
        });
    }
  }

  private _permitirAcessarVcp(status: Status) {
    this.podeAcessarVcp = status.etapa === EtapaABR.VCP_CONCLUIDA ||
      (status.etapa === EtapaABR.VCP_EM_ANDAMENTO && this.temPerfil('CERTIFICADORA')) ||
      (status.etapa === EtapaABR.VCP_APROVADA) ||
      (status.etapa === EtapaABR.VCP_REPROVADA);
  }

  private _carregarStatusPcnc() {
    if (this.podeVerSetor(SetoresAndamentoCertificacao.PCNC)) {
      
      this.loadingStatusPcnc = true;
      this.andamentoCertificacaoService
        .getStatusPcnc(this.unidadeProdutiva)
        .pipe(
          finalize(() => {
            this.loadingStatusPcnc = false;
          })
        )
        .subscribe((status) => {
          this.statusPcnc = status;
          this._carregarStatusVdp().then((data) => {
              const naoPodeAcessarPcnc =
              status.etapa === EtapaABR.NAO_INICIADO ||
              status.etapa === EtapaABR.PCNC_AUSENTE_DE_NAO_CONFORMIDADES ||
              (status.etapa === EtapaABR.VDP_ANDAMENTO && (this.temPerfil('PRODUTOR') || this.temPerfil('ABRAPA') || this.temPerfil('ESTADUAL'))) ||
              (this.statusVdp.etapa === EtapaABR.VDP_ANDAMENTO && (this.temPerfil('PRODUTOR') || this.temPerfil('ABRAPA') || this.temPerfil('ESTADUAL'))) ||
              status.etapa === EtapaABR.PCNC_EM_ANDAMENTO && !(this.temPerfil('ESTADUAL')) ||
              status.etapa === EtapaABR.VDP_OPTOU_PULAR_VDP ||
              this.statusVdp.etapa === EtapaABR.VDP_OPTOU_PULAR_VDP ||
              status.pulouEtapa === true ||
              status.ausenteNaoConformidade === true;
            this.podeAcessarPcnc = !naoPodeAcessarPcnc;
          });
          
        });
    }
  }

  private _carregarStatusAbr() {
    if (this.podeVerSetor(SetoresAndamentoCertificacao.ABR)) {
      this.loadingStatusAbr = true;
      this.andamentoCertificacaoService
        .getAndamentoFinalizacaoAbr(this.unidadeProdutiva)
        .pipe(
          finalize(() => {
            this.loadingStatusAbr = false;
          })
        )
        .subscribe((andamento) => {
          this.andamentoFinalizacaoAbr = andamento;

          this.podeAcessarCertificadoAbr =
            andamento.status.etapa.toUpperCase() === EtapaABR.CERTIFICADA_ABR_EM_ANDAMENTO_BCI.toUpperCase()  ||
            andamento.status.etapa.toUpperCase()  === EtapaABR.CERTIFICADA_ABR_CANCELADA_BCI.toUpperCase()  ||
            andamento.status.etapa.toUpperCase()  === EtapaABR.CERTIFICADA_ABR_LICENCIADO_BCI.toUpperCase()  ||
            andamento.status.etapa.toUpperCase() === EtapaABR.CERTIFICADA_ABR_NAO_OPTANTE_BCI.toUpperCase()  ||
            andamento.status.etapa.toUpperCase()  === EtapaABR.CERTIFICADA_ABR_REPROVADO_BCI.toUpperCase() ;
        });
    }
  }

  private _carregarStatusBci() {
    if (this.podeVerSetor(SetoresAndamentoCertificacao.BCI)) {
      this.loadingStatusBci = true;
      this.andamentoCertificacaoService
        .getAndamentoLicencaBci(this.unidadeProdutiva)
        .pipe(
          finalize(() => {
            this.loadingStatusBci = false;
          })
        )
        .subscribe((andamento) => {
          this.andamentoLicencaBci = andamento;

          this.podeAcessarDownloadBci = 
            this.andamentoLicencaBci.status.etapa === EtapaABR.CERTIFICADA_ABR_LICENCIADO_BCI;
        });
    }
  }

  temPerfil(permissao: string) {
    const role = this.permissionService.getPermission();
    return role.some((x) => x == permissao);
  }

  buscarFormulario(tipoFormulario: string) {

    if (!TipoFormulario[tipoFormulario]) {
      throw new Error("Formulario invalido");
    }

    const tipo = TipoFormulario[tipoFormulario];

    const service = this.criadorFormularioService.criarFormularioService(tipo);
    this.spinnerService.show();
    service.buscarFormulario(this.unidadeProdutiva).subscribe((x) => {
      window.open(x?.url + x?.hash, "_blank");
      this.spinnerService.hide();
    });
  }

  buscaTermoDeAdesao() {
    this.conviteService.getTermoDeAdesao(this.unidadeProdutiva).subscribe(res => {
      if (!!res.abr) {
        window.open(res.abr, "_blank");
      }
    });
  }

  buscarFormularioVCP() {
    if (this.podeAcessarVcp) {
      this.buscarFormulario('VCP');
    }
  }

  downloadCertificadoAbr() {
    this.spinnerService.show();
    this.certificadoAbrService
      .downloadCertificado(this.unidadeProdutiva.id)
      .subscribe((res: any) => {
        if (!res.conteudo) {
          this.toastService.showToast(ToastType.DANGER, "Falha", "Certificado não disponível");
          return;
        }

        const data = 'data:application/pdf;base64,' + res.conteudo;
        const link = document.createElement("a");
        const fileName = res.nomeArquivo;

        link.href = data;
        link.download = fileName;
        link.click();
      }
      ).add(() => {this.spinnerService.hide();});
    
  }

  downloadLicencaBci() {
    this.spinnerService.show();
    this.bciService
      .downloadLicencaBci(this.unidadeProdutiva)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe((res) => {
        const data = 'data:application/pdf;base64,' + res.conteudo;
        const link = document.createElement("a");
        const fileName = res.nomeArquivo;
        link.href = data;
        link.download = fileName;
        link.click();
      });

  }

  setor(chaveSetor: string): SetoresAndamentoCertificacao {
    return SetoresAndamentoCertificacao[chaveSetor];
  }

  podeVerSetor(setor: SetoresAndamentoCertificacao) {
    return this.permissionService
      .getPerfisValidos()
      ?.some((role) =>
        this.andamentoCertificacaoService.podeVerSetor(
          PerfilUsuario[role],
          setor
        )
      );
  }

  _verificarCertificadoAbr() {
    this.certificadoAbrService
      .verificarCertificado(this.unidadeProdutiva.id)
      .subscribe((res) => {
       this.podeEfeturaDownload = res;
      });
  }

  private _podeAcessarTermoAdesao() {
    this.vcpService
      .getStatusAtualSafraSelecionada(this.unidadeProdutiva)
      .subscribe((statusAtual) => {
        this.podeAcessarTermoAdesao = (statusAtual.etapa === EtapaABR.NAO_CONVIDADA || 
          statusAtual.etapa === EtapaABR.NAO_INICIADO || 
          statusAtual.etapa === EtapaABR.CERTIFICACAO_CANCELADA ||
          statusAtual.etapa === EtapaABR.CONVITE_REALIZADO_ABR);
      });
  }
}
