import { EventEmitter } from "@angular/core";
import { AlertModalService } from "src/app/services/alert-modal.service";
import { EtapaABR } from "./../../entities/etapas";
import { ToastService } from "./../../services/toast.service";
import { FinalizandoCertificacaoService } from "./../../services/finalizando-certificacao.service";
import { finalize, mergeMap, tap } from "rxjs/operators";
import {  NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, Input, OnInit, Output } from "@angular/core";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import { Status } from "../../entities/status";
import { UnidadeProdutiva } from "../../entities/unidade-produtiva";
import { CertificadoAbrService } from "../../services/certificado-abr.service";
import { SpinnerService } from "../../services/spinner.service";
import { Observable, of } from "rxjs";
import { EtapaService } from "src/app/services/etapa.service";
import { ToastType } from "src/app/entities/toast";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { VcpService } from "src/app/services/vcp.service";
import { AppService } from "src/app/services/app-service";
import { Services } from "src/app/services/service";

@Component({
  selector: 'app-modal-etapa-certificado-abr',
  templateUrl: './modal-etapa-certificado-abr.component.html',
  styleUrls: ['./modal-etapa-certificado-abr.component.css'],
})
export class ModalEtapaCertificadoAbrComponent implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() status: Status;
  @Output() atualizacaoRealizada = new EventEmitter();

  icon = faExclamationTriangle;
  pdfIcon = faFilePdf;

  certificacaoBCI$: Observable<any>;
  certificacaoABR$: Observable<any>;

  certificacaoABR: any;
  podeVerBotaoCancelarCertAbr =  false;
  podeSolicitarRetorno:boolean=false;
  etapaAtual: any;

  constructor(
    private certificadoAbrService: CertificadoAbrService,
    private spinnerService: SpinnerService,
    private etapaService: EtapaService,
    private finalizarCertificacaoService: FinalizandoCertificacaoService,
    private toastService: ToastService,
    private alertService: AlertModalService,
    private appService: AppService,
    private services: Services,
    private vcpService: VcpService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.carregarCertificacoes();
    this._podeSolicitarRetorno();
  }

  carregarCertificacoes() {
    this._carregaCertificacaoABR();
    this._carregaCertificacaoBCI();
    this._podeVerCancelarCertificacaoAbr();
  }

  private _carregaCertificacaoABR() {
    this.certificacaoABR$ = this.etapaService.getCertificadoABRSafraSelecionada(this.unidadeProdutiva);
    this.certificacaoABR$.subscribe(res => {
      this.certificacaoABR = res;
    })
  }
  private _podeSolicitarRetorno() {
    this.podeSolicitarRetorno = this.appService.safraSelecionadaEhVigente()  && this.unidadeProdutiva.etapaAbr.toUpperCase().includes('CERTIFICADA ABR');
    let safraEscolhida = JSON.parse(window.localStorage.getItem('safraEscolhida'));
    this.services.get(`UpCertificacao/listastatus/${this.unidadeProdutiva.id}/${safraEscolhida.id}`).subscribe(res => {
      let resfilter=res.filter(x=>x.id != 27);
      let lastItem = resfilter.pop();
      this.etapaAtual = lastItem;
    })
  }

  private _carregaCertificacaoBCI() {
    this.certificacaoBCI$ = this.etapaService.getCertificadoBCISafraSelecionada(this.unidadeProdutiva);
  }


  acessarDadosCertificado() {
    console.log('Acessar dados certificado');
  }

  downloadCertificadoAbr() {
    this.spinnerService.show();
    this.certificadoAbrService
      .downloadCertificado(this.unidadeProdutiva.id)
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe((res) => {

        const data = 'data:application/pdf;base64,' + res.conteudo;
        if (res.conteudo === '') {
          this.toastService.showToast(ToastType.DANGER, 'Falha', 'Certificado não disponível');
          return;
        }
        const link = document.createElement('a');
        const fileName = res.nomeArquivo;

        link.href = data;
        link.download = fileName;
        link.click();
      }).add(() => {this.spinnerService.hide();});
  }

  concluirCertificacaoABR() {
    if (this.verificarCertificacaoABR) {
      this.alertService
      .showConfirm(
          "Confirmar a conclusão da certificação ABR?",
          "Você confirma que deseja Concluir a Emissão do Certificado ABR?"
      )
        .pipe(
          mergeMap((confirmed) => {
        if (confirmed) {
          this.spinnerService.show();
              return this.finalizarCertificacaoService
                .finalizaCertificacaoABR(this.unidadeProdutiva)
                .pipe(
                  tap((res) => {
                    if (res?.code === 200) {
                      this.unidadeProdutiva.etapaAbr = EtapaABR.CERTIFICADA_ABR_EM_ANDAMENTO_BCI;
              this.toastService.showToast(
                        ToastType.SUCCESS,
                        "Sucesso",
                        res.message
              );
                    } else {
                      this.toastService.showToast(
                        ToastType.DANGER,
                        "Falha",
                        res?.message ? res.message : "Erro ao executar tarefa."
                      );
                    }
              this.carregarCertificacoes();
              this.atualizar();
              this.spinnerService.hide();
                  })
                );
        }
            return of();
          })
        )
        .subscribe();
    }
  }

  atualizar() {
    this.atualizacaoRealizada.emit();
  }

  get verificarCertificacaoABR(): boolean {

    const etapaPermite = this.unidadeProdutiva.etapaAbr === EtapaABR.CERTIFICACAO_EM_EMISSAO;
    return etapaPermite && this.numeroCertificadoValido;
  }

  get numeroCertificadoValido(): boolean {
    return !!this.certificacaoABR?.numero && this.certificacaoABR?.numero !== '0';
  }

  get tooltipConfirmar(): string {
    if (this.unidadeProdutiva.etapaAbr.includes('Certificada ABR ')) {
      return '';
    }
    return !this.verificarCertificacaoABR ? 'Você precisa definir o número do certificado para Concluir o processo.' : ''
  }

  private _podeVerCancelarCertificacaoAbr() {
    this.vcpService
      .getStatusAtualSafraSelecionada(this.unidadeProdutiva)
      .subscribe((statusAtual) => {
        this.podeVerBotaoCancelarCertAbr = (
          statusAtual.etapa === EtapaABR.ABR_CERTIFICADO_NAO_OPTANTE_BCI ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_EM_ANDAMENTO_BCI ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_LICENCIADO_BCI ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_REPROVADO_BCI ||
          statusAtual.etapa === EtapaABR.CERTIFICADA_ABR_CANCELADA_BCI 
        );
      });
  }

}
