<div class="p-3" style="background-color: #fff">
  <div class="d-flex align-items-center justify-content-end">
    <button class="btn btn-secondary" (click)="onClose()">X</button>
  </div>
  <p class="h4">
    <strong>{{ title }}</strong>
  </p>
  <div class="vdp-identification row">
    <div class="col-md-6">
      <p class="text-muted h6">Unidade Produtiva</p>
      <p class="h5">
        <strong>{{ unidadeProdutiva.nomeUnidadeProdutiva | titlecase }}</strong>
      </p>
    </div>
    <div class="col-md-6">
      <p class="text-muted h6">Safra de certificação</p>
      <p class="h5">
        <strong>{{ unidadeProdutiva.safraCertificacao }}ª</strong>
      </p>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-6">
      <p class="h6">Nota mínima necessária para perguntas de certificação</p>
      <p class="h5">
        <fa-icon style="font-size: 0.5rem" [icon]="icon" size="sm"></fa-icon>
        &nbsp;<strong
          >{{
            containerNota?.meta?.notaMinimaPerguntas ||
              getNotaPorSafra(unidadeProdutiva)
          }}
          %</strong
        >
      </p>
    </div>
    <div class="col-md-6">
      <p class="h6">
        Nota mínima necessária para perguntas de conformidade obrigatória
      </p>
      <p class="h5">
        <fa-icon style="font-size: 0.5rem" [icon]="icon" size="sm"></fa-icon
        >&nbsp;<strong>100 %</strong>
      </p>
    </div>
  </div>
  <hr />
  <p>
    <strong> Nota obtida por Critério </strong>
  </p>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th class="col-3">
            <p class="text-muted mx-3">Critérios</p>
          </th>
          <th>
            <p class="text-muted">Itens</p>
          </th>
          <th scope="col">
            <p class="text-muted text-center">Itens de Certificação</p>
          </th>
          <th scope="col">
            <p class="text-muted text-center">Itens (CMP)</p>
          </th>
          <th scope="col">
            <p class="text-muted text-center">Itens de certificação</p>
          </th>
          <th scope="col">
            <p class="text-muted text-center">
              Itens de conformidade obrigatória (eliminatória)
            </p>
          </th>
          <th scope="col">
            <p class="text-muted text-center">Itens Reprovados</p>
          </th>
          <th scope="col">
            <p class="text-muted text-center">Itens não respondidos</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let i of containerNota.data">
          <td class="col-3 text-center">
            <strong>{{ i.criterio || "" }}</strong>
          </td>
          <td class="text-center">
            <strong>{{ i.quantidadeItensTotal || "" }}</strong>
          </td>
          <td class="text-center">
            <strong>{{ i.quantidadeItensCertificacao || "" }}</strong>
          </td>
          <td class="text-center">
            <strong>{{ i.quantidadeItensCMP }}</strong>
          </td>
          <td class="text-center">
            <span
              [ngClass]="{
                'dot-green': i.aprovadoPerguntas === true,
                'dot-red': i.aprovadoPerguntas === false
              }"
            >
            </span>
            {{ i.porcentagemPerguntas || 0 }} %
          </td>
          <td class="text-center">
            <span
              [ngClass]="{
                'dot-green': i.aprovadoPerguntasConformidade === true,
                'dot-red': i.aprovadoPerguntasConformidade === false
              }"
            >
            </span>
            {{ i.aprovadoPerguntasConformidade ? "Aprovado" : "Reprovado" }}
          </td>
          <td class="text-center mx-4">
            <strong>
              <span *ngIf="i?.itensReprovados.length < 12">{{
                i.itensReprovados | slice : 0 : 11
              }}</span>
              <span
                *ngIf="i?.itensReprovados.length >= 12"
                placement="left"
                [ngbTooltip]="i.itensReprovados"
                >{{ i.itensReprovados | slice : 0 : 11 }}...</span
              >
            </strong>
          </td>
          <td class="text-center mx-4">
            <strong>
              <span *ngIf="i?.itensNaoRespondidos.length < 12">
                {{ i.itensNaoRespondidos | slice : 0 : 11 }}
              </span>
              <span
                *ngIf="i?.itensNaoRespondidos.length >= 12"
                placement="left"
                [ngbTooltip]="i.itensNaoRespondidos"
              >
                {{ i.itensNaoRespondidos | slice : 0 : 11 }}...
              </span>
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="!containerNota.temNota || !containerNota.data.length">
    <p>
      Os Critérios e Itens poderão ser visualizados a medida que o formulário
      for preenchido e salvo
    </p>
  </div>
  <hr />
  <div *ngIf="containerNota.data && containerNota.data.length > 0">
    <span class="h5"
      ><strong
        >Resultado final:
        <span
          [ngClass]="{
            'dot-green-large': containerNota.meta.aprovado === true,
            'dot-red-large': containerNota.meta.aprovado === false
          }"
        >
        </span
        >{{ containerNota.meta.aprovado ? "Aprovado" : "Reprovado" }}
      </strong></span
    >
  </div>
  <div class="d-flex align-items-center justify-content-start my-4">
    <button class="btn btn-secondary" (click)="onClose()">Fechar</button>
  </div>
</div>
