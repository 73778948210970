import { Component, Input } from "@angular/core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { BaseComponent } from "src/app/base/base.component";
import { RespostaAceite } from "src/app/entities/respostaAceite";
import { Status } from "src/app/entities/status";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";

@Component({
  selector: "modal-aceite-convite",
  templateUrl: "./modal-aceite-convite.component.html",
  styleUrls: ["./modal-aceite-convite.component.css"],
})
export class ModalAceiteConviteComponent extends BaseComponent {
  iconLink = faExternalLinkAlt;
  @Input() unidadeProdutiva: UnidadeProdutiva = null;
  public linkCertificacaoAbr: string = null;
  public linkCertificacaoBci: string = null;
  public aceites: RespostaAceite = null;
  public status: Status;

  ngOnInit() {
    this.loading();
  }

  loading() {
    this.showSpinner();
    this.service
      .getBy(`AceiteConvite`, this.unidadeProdutiva.id)
      .subscribe((x) => {
        this.aceites = x.data;
        this.hideSpinner();
      });
    this.service
      .getBy("HistoricoCertificacao/getStatus", this.unidadeProdutiva.id)
      .subscribe((x) => {
        this.status = x;
      });
  }

  redirecionarAceiteAbr() {
    var windowReference = window.open();

    this.service
      .getBy(`AceiteConvite/linkAceiteAbr`, this.unidadeProdutiva.id)
      .subscribe((x) => {
        this.linkCertificacaoAbr = x.data;

        windowReference.location.href = this.linkCertificacaoAbr;
      });
  }

  redirecionarAceiteBci() {
    var windowReference = window.open();

    this.service
      .getBy(`AceiteConvite/linkAceiteBci`, this.unidadeProdutiva.id)
      .subscribe((x) => {
        this.linkCertificacaoBci = x.data;
        windowReference.location.href = this.linkCertificacaoBci;
      });
  }
}
